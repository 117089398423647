<!-- App.svelte -->
<script>
  import { Router, Route } from "svelte-routing";
  import { cookieStore } from "utils/store.js";
  import { getCookie } from "utils/tools.js";

  // Admin Layout
  import Layout from "./layouts/Layout.svelte";
  // Auth Layout
  import Login from "views/Login.svelte";
  import AddUser from "views/User.svelte";
  import ModtfyUser from "views/ModtfyUser.svelte";
  import Dashboard from "views/Dashboard.svelte";
  import Team from "views/Team.svelte";
  import Project from "views/Project.svelte";
  import PrintPage from "views/PrintPage.svelte";
  import ProjectManagement from "views/ProjectManagement.svelte";
  import ProjectRegister from "views/ProjectRegister.svelte";
  import MyInfo from "views/MyInfo.svelte";

  let hasCookie;

  if (getCookie("token")) {
    cookieStore.update((data) => {
      data = true;
      hasCookie = true;
    });
  } else {
    cookieStore.update((data) => {
      data = false;
      hasCookie = false;
    });
  }
  
  let userInfo;
  if(getCookie("user")) {
    userInfo = JSON.parse(getCookie("user"))
  }

</script>

{#if !hasCookie}
  <Router>
    <Route path="/*" component="{Login}" />
  </Router>
{:else}
  <Router>
    <Route path="project/print/:id" component="{PrintPage}" />
    <Layout>
      {#if userInfo.auth >= "6"}      
        <Route path="project/register" component="{ProjectRegister}" />
      {:else}
        <Route path="project" component="{Project}" />
      {/if}
      <Route path="" component="{Dashboard}" />
      <Route path="project/management" component="{ProjectManagement}" />
      <Route path="project/:id" component="{Project}" />

      {#if userInfo.auth == "0"}
      <Route path="team" component="{Team}" />
      <Route path="user" component="{AddUser}" />
      <Route path="modify-user" component="{ModtfyUser}" />
      {/if}
      <Route path="/myinfo" component="{MyInfo}" />
      <Route path="/*" component="{Dashboard}" />
    </Layout>
  </Router>
{/if}

