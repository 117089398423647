<script>
    import { apiFetch } from "utils/tools.js"
    export let id;
    export let items;
    export let handleProjectModal;
    export let handleProjectInfoGet;
    
    let formList = [
        {title: "계약금액", name: 'contract_amount'},
        {title: "기성(준공)금액", name: 'completion_amount'},
        {title: "실행금액", name: 'execution_amount'},
        {title: "본사관리비 (%)", name: 'management_rate'},
        {title: "공정률 (%)", name: 'process_rate'},
    ]

    let handleProjectInfoSubmit = async () => {
        let inputValue = document.querySelectorAll('#inputValue')

        let projectInfoValue = [];
        inputValue.forEach((item)=>{
            projectInfoValue = [...projectInfoValue, item.value]
        })
        
        const params = {
            project_id: id,
            contractor: projectInfoValue[0], 
            due_date: projectInfoValue[1],
            contract_amount: projectInfoValue[2], 
            completion_amount: projectInfoValue[3], 
            execution_amount: projectInfoValue[4],
            management_rate: projectInfoValue[5],
            process_rate: projectInfoValue[6]
        }

        let res;
        let item = await items 
        item
        ? res = await apiFetch(`project/direct/info/${id}`, "put", params)
        : res = await apiFetch(`project/direct/info/${id}`, "post", params);

        alert(res.data)

        if (res.state == 1) {
            handleProjectModal()
            handleProjectInfoGet()
        }
    };
</script>

<div class="modal-form">
    <div class="modal-cover"></div>
    <div class="modal">
        <i 
            class="fas fa-times modal-close"
            on:click={handleProjectModal}
        ></i>
        <div class="text-blueGray-400 text-center mb-3 font-bold">
            <p>프로젝트 정보</p>
        </div>
        <form on:submit|preventDefault={handleProjectInfoSubmit}>
            <div class="modal-form">

                {#if !items}
                    <div class="flex">
                        <div class="modal-form w-full">
                            <div class="block uppercase text-blueGray-600 text-xs font-bold m-2">
                                계약업체
                            </div>
                            <input
                                id="inputValue"
                                placeholder="업체명을 입력해주세요"
                                required
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            />
                        </div>
                        <div class="modal-form w-full pl-3">
                            <div class="block uppercase text-blueGray-600 text-xs font-bold m-2">
                                준공예정일
                            </div>
                            <input
                                id="inputValue"
                                value="{new Date().toISOString().substring(0, 10)}"
                                type="date"
                                required
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            />
                        </div>
                    </div>

                    {#each formList as list}
                        <div class="block uppercase text-blueGray-600 text-xs font-bold m-2">
                            {list.title}
                        </div>
                        <input
                            id="inputValue"
                            value="0"
                            type="number" 
                            on:mousewheel={(e)=>{e.preventDefault()}}
                            step="0.01"
                            required
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                    {/each}
                {:else}
                    <div class="flex">
                        <div class="modal-form w-full">
                            <div class="block uppercase text-blueGray-600 text-xs font-bold m-2">
                                계약업체
                            </div>
                            <input
                                id="inputValue"
                                placeholder="업체명을 입력해주세요"
                                value="{items['contractor']}"
                                required
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            />
                        </div>
                        <div class="modal-form w-full pl-3">
                            <div class="block uppercase text-blueGray-600 text-xs font-bold m-2">
                                준공예정일
                            </div>
                            <input
                                id="inputValue"
                                value="{items['due_date']}"
                                type="date"
                                required
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            />
                        </div>
                    </div>
                    {#each formList as list}
                        <div class="block uppercase text-blueGray-600 text-xs font-bold m-2">
                            {list.title}
                        </div>
                        <input
                            id="inputValue"
                            required
                            value={items[list.name]}
                            type="number"
                            on:mousewheel={(e)=>{e.preventDefault()}}
                            step="0.01"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                    {/each}
                {/if}
            </div>
            <button 
                class="bg-indigo-500 text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                style="width:100%;"
            >저장</button>
        </form>
    </div>
</div>