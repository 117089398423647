<script>
    import { apiFetch } from "utils/tools.js"
    import Card from "../layouts/CardLayout.svelte";
    import UserList from 'components/ModifyUser/UserList.svelte';
    import UserModal from 'components/ModifyUser/Modal/UserModal.svelte';

    let title = "관리자 수정"
    let userModal = false;
    let modifyUserInfo;

    $: items = apiFetch("user");
  
    const handleUserGet = async (setIndex) => {
        items = await apiFetch("user");
    }

    const handleUserModal = async (setIndex) => {
        if (setIndex) {
            let userList = await items
            userList.forEach((item) => {
                if(item["id"] == setIndex) modifyUserInfo = item;
            })
        }
        userModal = !userModal
    }
</script>

<Card {title}>
    <UserList {handleUserModal} {items}/>
</Card>
{#if userModal}
    <UserModal {handleUserModal} {modifyUserInfo} {handleUserGet} {items} />
{/if}