<script>
  import { apiFetch } from "utils/tools.js"

  const admins= [
    { position: "담당", auth:"7" },
    { position: "팀장", auth:"6" },
    { position: "자산구매팀장", auth:"5" },
    { position: "경영지원팀장", auth:"4" },
    { position: "총괄", auth:"3" },
    { position: "이사", auth:"2" },
    { position: "대표이사", auth:"1" },
  ]

  let identity, password, name, auth;

  const handleUserCreate = async () => {

    const params = {
      identity,
      password,
      name,
      auth,
    }

    const items = await apiFetch("user", "post", params)

    if (items.state == 1) {
      identity = "";
      password = "";
      name = "";
    }

    alert(items.data)
  }
</script>

<div class="container mx-auto px-4 h-full">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-6/12 px-4">
        <div class="flex-auto px-4 lg:px-10 pt-0">
          <div class="text-blueGray-400 text-center mb-3 font-bold">
            <small>관리자 등록</small>
          </div>
          <form on:submit|preventDefault={ handleUserCreate }>
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-name"
              >
                아이디
              </label>
              <input
                id="grid-name"
                bind:value={identity}
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="아이디(4자~12자)"
                required
              />
            </div>
            <div class="relative w-full mb-3">
              <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      for="grid-password"
              >
                비밀번호
              </label> 
              <input
                      id="grid-password"
                      bind:value={password}
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="비밀번호(4자~12자)"
                      required
              />
            </div>
            <div class="relative w-full mb-3">
              <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      for="grid-password"
              >
                이름
              </label> 
              <input
                      id="grid-password"
                      bind:value={name}
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="관리자 이름"
                      required
              />
            </div>
            <div class="relative w-full mb-3">
              <div
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                직무
              </div>
              <select class="text-sm relative border-0 rounded shadow w-full mb-3 h-10" bind:value={auth}>
                {#each admins as admin}
                  <option value="{admin.auth}">{admin.position}</option>
                {/each}
              </select>
            </div>
            <div class="text-center mt-6">
              <button
                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                type="submit"
              >
                관리자 등록
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
</div>  