<script>
  import { apiFetch } from "utils/tools.js"
  export let items;
  export let handleTeamModal;
  export let handelTeamRead;
  export let managerList;
  export let teamLeaderList;
  export let modifyTeamInfo;

  let team_id = modifyTeamInfo.id;
  let name = modifyTeamInfo.name;
  let before_user_id = modifyTeamInfo.leaderIndex;
  let leaderIndex = modifyTeamInfo.leaderIndex;
  let postManager;
 
  $: console.log("test", modifyTeamInfo);

  const handleTeamUpdate = async () => {
    const params = {
      team_id,
      name
    }
    console.log(params);
    items = await apiFetch("team", "put", params);
    alert(items.data)

    if (items.state == 1) {
      handelTeamRead();
      // handleTeamModal()
    }
  }

  const handleTeamLeaderUpdate = async () => {
    const params = {
      team_id,
      before_user_id,
      user_id: leaderIndex
    }

    if (before_user_id[0]) {
      items = await apiFetch("team/leader", "put", params);
    } else {
      items = await apiFetch("team/leader", "post", params);
    }
    alert(items.data)

    if (items.state == 1) {
      handelTeamRead();
      // handleTeamModal()
      before_user_id = leaderIndex
    }
  }

  const handleTeamManager = async (setType, value, managerIndex) => {
    // 0: delete, 1: post
    const params = {
      team_id,
      user_id: value
    }
    
    if (setType == 1) {
      items = await apiFetch("team/manager", "post", params);
      console.log(modifyTeamInfo)
    } else if (setType == 0) {
      const checkConfirm = confirm("담당자를 삭제하시겠습니까?");

      if(checkConfirm) {
        items = await apiFetch("team/manager", "delete", params );
      };

    } else {
      return alert("잘못된 접근입니다.")
    }

    if(items.data) alert(items.data);

    if (items.state == 1) {
      if (setType == 0) {
        document.querySelector(`.manager${managerIndex}`).style.display = "none"
      } else {
        handleTeamModal()
      };
      handelTeamRead();
    }
  }

</script>

<div class="modal-form">
    <div class="modal-cover"></div>
    <div class="modal">
      <i 
          class="fas fa-times modal-close"
          on:click={handleTeamModal}
      ></i>
      <div class="text-blueGray-400 text-center mb-3 font-bold">
          <p>팀 관리</p>
      </div>
      <div class="relative w-full mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          for="grid-name"
        >
          팀 이름
        </label>
          <div class="flex">
            <input
              id="grid-name"
              bind:value={name}
              type="text"
              class="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              required
            />
            <button
              class="align-middle text-center bg-blueGray-800 text-white active:bg-blueGray-600 mb-1 mt-1 ml-2 font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
              style="width: 65px;"
              on:click={handleTeamUpdate}
            >
              저장
            </button>
          </div>
        </div>
        <div class="relative w-full mb-3">
          <div
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            팀장
          </div>
          <div class="flex">
            <select 
              class="text-sm border-0 rounded shadow relative w-full h-10" 
              bind:value={leaderIndex}
              required
            >
              <option value="">선택</option>
              {#each teamLeaderList as item}
                {#if leaderIndex ==  item.id} 
                  <option selected value={item.id}>
                    {item.name}({item.identity})
                  </option>
                  {:else}
                  <option value={item.id}>
                    {item.name}({item.identity})
                  </option>
                {/if}
              {/each}
            </select>
            <button
              on:click={handleTeamLeaderUpdate}
              class="align-middle text-center bg-blueGray-800 mt-1 mb-1 mt-1 text-white active:bg-blueGray-600 ml-2 font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
              style="width: 65px;"
            >
              저장
            </button>
          </div>
        </div>
        <div class="relative w-full mb-3">
          <div
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            담당
          </div>
          {#each modifyTeamInfo.manager as item, index}
          <div class="flex manager{index}">
            <input
              id="grid-name"
              bind:value={item}
              type="text"
              class="border-0 px-3 py-2 mb-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              required
            />
            <button
              on:click={()=>{handleTeamManager(0, modifyTeamInfo.managerIndex[index], index)}}
              class="align-middle text-center bg-red-500 text-white active:bg-red-400 mb-4 mt-1 ml-2 font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
              style="width: 65px;"
            >
              삭제
            </button>
          </div>
          {/each}
          <div class="flex">
            <select 
              class="text-sm border-0 rounded shadow relative w-full h-10" 
              bind:value={postManager}
              required
            >
              <option value="">선택</option>
              {#each managerList as item}
                <option value={item.id}>
                  {item.name}({item.identity})
                </option>
              {/each}
            </select>
            <button
              on:click={()=>{handleTeamManager(1, postManager)}}
              class="align-middle text-center bg-blueGray-800 text-white active:bg-blueGray-600 mb-1 mt-1 ml-2 font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
              style="width: 65px;"
            >
              추가
            </button>
          </div>
        </div>
    </div>
</div>