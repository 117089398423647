<script>
    import { onMount } from "svelte";
    import VATModal from 'components/Project/Outsourcing/Modal/VATModal.svelte';
    import { apiFetch } from 'utils/tools.js'
    export let id;
    export let history;

    let TAX = 0;
    let VAT = 0;
    let executionVAT = 0;
    let setVATModal = false;

    const VATcalculation = () => {
        if(history) {
            TAX = 0;
            VAT = 0;
            history["main"].forEach((item) => {
                TAX += item.expense_tax + item.material_tax + item.subcontract_0_tax +  item.subcontract_1_tax +  item.subcontract_2_tax +  item.subcontract_3_tax +  item.subcontract_4_tax
            });
            history["sub"].forEach((item) => {
                TAX += item.expense_tax + item.material_tax + item.subcontract_0_tax +  item.subcontract_1_tax +  item.subcontract_2_tax +  item.subcontract_3_tax +  item.subcontract_4_tax
            });
            VAT = Math.floor(TAX / 10);
        }
    }

    const getVATValue = async () => {
        const [res] = await apiFetch(`project/${id}`)
        executionVAT = res.vat
    }

    const handleVATModal = () => {
        setVATModal = !setVATModal;
    }

    onMount(() => {
        getVATValue()
    })

    $: {
        history, VATcalculation();
    }
</script>

<div style="display: flex; justify-content: space-between; align-items: center;">
    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold">
        부가세
    </h6>
        <button 
            class="bg-indigo-500 text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
            type="button"
            style="height: fit-content;"
            on:click={handleVATModal}
        >
            수정
        </button>
</div>

<div class="project-info-wrap" style="max-width: max-content;">
    <table class="project-info">
        <thead class="project-info-header">
            <tr>
                <th style="width:200px;">계약내역부가세</th>
                <th style="width:200px;">세금계산서 합</th>
                <th style="width:200px;">집행부가세</th>
                <th style="width:200px;">부가세 차익</th>
            </tr>
        </thead>
        <tbody class="project-info-body">
            {#if !history}
            <tr>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
            </tr>
            {:else}
                <tr>
                    <td>{executionVAT.toLocaleString()}</td>
                    <td>{TAX.toLocaleString()}</td>
                    <td>{VAT.toLocaleString()}</td>
                    <td>{(executionVAT - VAT).toLocaleString()}</td>
                </tr>            
            {/if}
        </tbody>
    </table>
</div>

{#if setVATModal}
    <VATModal {handleVATModal} {getVATValue} {executionVAT} {id}/>
{/if}