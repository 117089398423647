<script>
    import { apiFetch } from "utils/tools.js";
    export let id;
    export let getVATValue;
    export let handleVATModal;
    export let executionVAT;

    let handleVATInfoSubmit = async () => {
        
        const params = {
            vat: executionVAT,
        }
        let res = await apiFetch(`project/${id}`, "put", params)

        alert(res.data)

        if (res.state == 1) {
            getVATValue()
            handleVATModal()
        }
    };

</script>

<div class="modal-form">
    <div class="modal-cover"></div>
    <div class="modal">
        <i 
            class="fas fa-times modal-close"
            on:click={handleVATModal}
        ></i>
        <div class="text-blueGray-400 text-center mb-3 font-bold">
            <p>부가세</p>
        </div>
        <form on:submit|preventDefault={handleVATInfoSubmit}>
            <div class="modal-form">
                <hr class="mt-6 border-b-1 border-blueGray-300" />
                <div class="flex">
                    <div class="modal-form w-full">
                        <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            계약내역부가세
                        </div>
                        <input
                            bind:value={executionVAT}
                            required
                            type="number" 
                            on:mousewheel={(e)=>{e.preventDefault()}}
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                    </div>
                </div>
            </div>
            <button 
                class="bg-indigo-500 text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                style="width:100%;"
            >저장</button>
        </form>
    </div>
</div>