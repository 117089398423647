<script>
    export let switchingActive;
    export let handelSwitchButton;
</script>

<div class="switching-tap"> 
    <div
        on:click={handelSwitchButton} 
        class="team-button {!switchingActive ? "bg-blueGray-700 text-white" : "bg-white text-blueGray-700"} active:bg-blueGray-600 outline-none focus:outline-none shadow hover:shadow-lg ease-linear transition-all duration-150">
        팀 추가
    </div>
    <div 
        on:click={handelSwitchButton}
        class="team-button {switchingActive ? "bg-blueGray-700 text-white" : "bg-white text-blueGray-700"} active:bg-blueGray-600 outline-none focus:outline-none shadow hover:shadow-lg ease-linear transition-all duration-150">
        팀 관리
    </div>
</div>