<script>
    import { onMount } from "svelte";
    import PrepaymentModal from 'components/Project/Modal/PrepaymentModal.svelte';
    import { apiFetch } from 'utils/tools.js'
    export let id;

    let setPrepaymentModal = false;
    let prepayment = [];
    let prepaymentIndex = null
    let prepaymentTotal = 0

    const getPrepaymentValue = async () => {
        const res = await apiFetch(`project/prepayment/${id}`)
        prepayment = res;
        prepaymentTotal = 0
        prepayment.forEach((item, index)=>{
            prepaymentTotal += item.amount
        })
    }

    const handlePrepaymentModal = (setIndex = null) => {
        prepaymentIndex = setIndex;
        setPrepaymentModal = !setPrepaymentModal;
    }

    onMount(() => {
        getPrepaymentValue();
    })

</script>

<div style="display: flex; justify-content: space-between; align-items: center;">
    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold">
        선금
    </h6>
        <button 
            class="bg-indigo-500 text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
            type="button"
            style="height: fit-content;"
            on:click={()=>{handlePrepaymentModal()}}
        >
            추가
        </button>
</div>

<div class="project-info-wrap" style="max-width: max-content;">
    <table class="project-info">
        <thead class="project-info-header">
            <tr>
                <th style="width:200px;">선금지급일</th>
                <th style="width:200px;">지급받은금액</th>
                <th style="width:200px;">비고</th>
                <th id="printHidden" style="width:200px;">수정</th>
            </tr>
        </thead>
        <tbody class="project-info-body">
            {#if !prepayment[0]}
            <tr>
                <td colspan="4">데이터가 없습니다.</td>
            </tr>
            {:else}
                {#each prepayment as item}
                    <tr>
                        <td>{item.date}</td>
                        <td>{item.amount.toLocaleString()}</td>
                        <td>{item.note}</td>
                        <td id="printHidden">       
                            <button 
                            class="bg-teal-500 text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                            type="button"
                            style="height: fit-content;"
                            on:click={()=>{handlePrepaymentModal(item.id)}}
                        >
                            수정
                        </button></td>
                    </tr>
                {/each}   
                <tr class="project-info-total">
                    <td>합계</td>
                    <td>{prepaymentTotal.toLocaleString()}</td>
                    <td>-</td>
                    <td id="printHidden">-</td>
                </tr>
            {/if}
        </tbody>
    </table>
</div>

{#if setPrepaymentModal}
    <PrepaymentModal {getPrepaymentValue} {handlePrepaymentModal} {prepaymentIndex} {prepayment} {id}/>
{/if}