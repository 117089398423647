<script>
  import { apiFetch } from "utils/tools.js"
  export let handleUserModal;
  export let handleUserGet;
  export let modifyUserInfo;
  export let items;

  const admins= [
    { position: "담당", auth:"7" },
    { position: "팀장", auth:"6" },
    { position: "자산구매팀장", auth:"5" },
    { position: "경영지원팀장", auth:"4" },
    { position: "총괄", auth:"3" },
    { position: "이사", auth:"2" },
    { position: "대표이사", auth:"1" },
  ]

  let id = modifyUserInfo.id;
  let identity = modifyUserInfo.identity;
  let password = modifyUserInfo.password;
  let name = modifyUserInfo.name;
  let auth = modifyUserInfo.auth;

  const handleUserUpdate = async () => {

      let params = {
          id,
          identity,
          password, 
          name, 
          auth
      }

      items = await apiFetch("user", "put", params);
      
      alert(items.data)

      if (items.state == 1) {
          handleUserGet()
          handleUserModal()
      }
  }
</script>

<div class="modal-form">
    <div class="modal-cover"></div>
    <div class="modal">
        <i 
            class="fas fa-times modal-close"
            on:click={handleUserModal}
        ></i>
        <div class="text-blueGray-400 text-center mb-3 font-bold">
            <p>프로젝트 정보</p>
        </div>
        <form on:submit|preventDefault={handleUserUpdate}>
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-name"
              >
                아이디
              </label>
              <input
                id="grid-name"
                bind:value={identity}
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="아이디(4자~12자)"
                required
              />
            </div>
            <div class="relative w-full mb-3">
              <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      for="grid-password"
              >
                비밀번호
              </label> 
              <input
                      id="grid-password"
                      bind:value={password}
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="비밀번호(4자~12자)"
                      required
              />
            </div>
            <div class="relative w-full mb-3">
              <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      for="grid-password"
              >
                이름
              </label> 
              <input
                      id="grid-password"
                      bind:value={name}
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="비밀번호(4자~12자)"
                      required
              />
            </div>
            <div class="relative w-full mb-3">
              <div
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                직무
              </div>
              <select class="text-sm relative border-0 rounded shadow w-full mb-3 h-10" bind:value={auth}>
                {#each admins as admin}
                  <option value="{admin.auth}">{admin.position}</option>
                {/each}
              </select>
            </div>
            <div class="text-center mt-6">
              <button
                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                type="submit"
              >
                관리자 수정
              </button>
            </div>
        </form>
    </div>
</div>