<script>
    import Card from "../layouts/CardLayout.svelte";
    import Tap from "components/ProjectManagement/Tap.svelte";
    import Management from "components/ProjectManagement/Management.svelte";
    import EndProject from "components/ProjectManagement/EndProject.svelte";

    const title = "프로젝트 관리";
    let switchingActive = false;

    const divisions = [
        { id: 1, name: `직영` },
        { id: 2, name: `외주` },
    ];

    const handelSwitchButton = () => {
        switchingActive = !switchingActive
    }


</script>

<Card {title} >
    <Tap {handelSwitchButton} {switchingActive}/>
    {#if !switchingActive}
        <Management {divisions}/>
    {:else}
        <EndProject {divisions}/>
    {/if}
</Card>

