<script>
    import {apiFetch} from "utils/tools.js"
    export let handleLogModal;
    export let subcontractList;
    export let logIndex;

    let logHistory;

    const callHistoryLog = async () => {
        const items = await apiFetch(`project/direct/history/log/${logIndex}`)
        logHistory = items
    }

    callHistoryLog()

</script>

<div class="modal-form">
    <div class="modal-cover"></div>
    <div class="modal" style="max-width: 1400px;">
        <i 
            class="fas fa-times modal-close"
            on:click={handleLogModal}
        ></i>
        <div class="text-blueGray-400 text-center mb-3 font-bold">
            <p>수정 내역</p>
        </div>
        <div class="history-info-wrap">
            <table class="history-info">
                <thead class="history-info-header">
                    <tr>
                        <th rowspan="3" style="background: #dcedff">아이디</th>
                        <th rowspan="3" style="background: #dcedff">수정자</th>
                        <th rowspan="3" style="background: #dcedff">수정일</th>
                        <th rowspan="3">정산일</th>
                        <th rowspan="2" colspan="2">기성금액</th>
                        <th colspan="10">당사 현장매입자료(자재비, 노무비, 경비)</th>
                        <th rowspan="3">미지급금액</th>
                        <th class="" colspan="{subcontractList.length*2}">공동도급사 집행금액</th>
                        <th rowspan="3">비고</th>
                    </tr>
                    <tr>
                        <th colspan="4">자재비</th>
                        <th colspan="3">노무비</th>
                        <th colspan="3">경비</th>
                        {#each subcontractList as list}
                            <th class="" colspan="2">{list}</th>
                        {/each}
                    </tr>
                    <tr>
                        <th class="history-emphasisA">당사기성금액</th>
                        <th class="history-emphasisA">공동도급사<br>기성급액(당사분)</th>
                        <th>본사자재</th>
                        <th>원장</th>
                        <th>세금계산서</th>
                        <th>카드, 영수증</th>
                        <th>상용(사무직)</th>
                        <th>상용(기술직)</th>
                        <th>일용</th>
                        <th>세금계산서</th>
                        <th>안전관리비</th>
                        <th>카드,영수증</th>
                        {#each subcontractList as list}
                            <th class="" >노무비•경비</th>
                            <th class="" >세금계산서</th>
                        {/each}
                    </tr>
                </thead>
                <tbody class="history-info-body">
                    {#if logHistory}
                        {#each logHistory as historyData}
                            <tr class="{historyData.status == 1 ? 'log-create' : (historyData.status == 0 ? 'log-delete' :'log-modify')}">
                                <td>{historyData.identity}</td>
                                <td>{historyData.modified_by}</td>
                                <td>{historyData.modified_at}</td>
                                <td>{historyData.calculate_date}</td>
                                <td class="history-emphasisA">{historyData.original_contract.toLocaleString()}</td>
                                <td class="history-emphasisA">{historyData.receive_amount.toLocaleString()}</td>
                                <td>{historyData.material.toLocaleString()}</td>
                                <td>{historyData.material_ledger.toLocaleString()}</td>
                                <td>{historyData.material_tax.toLocaleString()}</td>
                                <td>{historyData.material_receipt.toLocaleString()}</td>
                                <td>{historyData.labor_office.toLocaleString()}</td>
                                <td>{historyData.labor_technical.toLocaleString()}</td>
                                <td>{historyData.labor_daily.toLocaleString()}</td>
                                <td>{historyData.expense_tax.toLocaleString()}</td>
                                <td>{historyData.expense_safety.toLocaleString()}</td>
                                <td>{historyData.expense_receipt.toLocaleString()}</td>
                                <td>{historyData.our_non_payment.toLocaleString()}</td>
                                {#each subcontractList as list, index}
                                    <td>{historyData[`subcontract_${index}_labor`].toLocaleString()}</td>
                                    <td>{historyData[`subcontract_${index}_tax`].toLocaleString()}</td>
                                {/each}
                                <td>{historyData.note ? historyData.note : "-"}</td>
                            </tr>
                        {/each}
                    {/if}
                </tbody>
            </table>
        </div>       
        <div class="log-info">
            <div class="log-create-info">
                <div class="info-circle"></div>
                <p>추가</p>
            </div>
            <div class="log-modify-info">
                <div class="info-circle"></div>
                <p>수정</p>
            </div>
            <div class="log-delete-info">
                <div class="info-circle"></div>
                <p>삭제</p>
            </div>
        </div>
    </div>
</div>
