<script>
    import { apiFetch } from "utils/tools.js";
    export let id;
    export let projectInfoData;
    export let handleProjectModal;
    export let handleProjectInfoGet;
    export let projectList;



    let contractor;
    let due_date = new Date().toISOString().substring(0, 10);
    let contractor_price = 0;
    let contractor_outsourcing_price = 0;
    let contractor_execution_rate = 0;
    let price = [0, 0, 0, 0, 0];
    let outsourcing = [0, 0, 0, 0, 0];
    let execution_rate = [0, 0, 0, 0, 0];
    
    if(projectInfoData) {
        contractor = projectInfoData.contractor
        due_date = projectInfoData.due_date
        contractor_price = projectInfoData.contractor_price,
        contractor_outsourcing_price = projectInfoData.contractor_outsourcing_price,
        contractor_execution_rate = projectInfoData.contractor_execution_rate,
        price[0] = projectInfoData.subcontract_0_price
        outsourcing[0] = projectInfoData.subcontract_0_outsourcing_price
        execution_rate[0] = projectInfoData.subcontract_0_execution_rate
        price[1] = projectInfoData.subcontract_1_price
        outsourcing[1] = projectInfoData.subcontract_1_outsourcing_price
        execution_rate[1] = projectInfoData.subcontract_1_execution_rate
        price[2] = projectInfoData.subcontract_2_price
        outsourcing[2] = projectInfoData.subcontract_2_outsourcing_price
        execution_rate[2] = projectInfoData.subcontract_2_execution_rate
        price[3] = projectInfoData.subcontract_3_price
        outsourcing[3] = projectInfoData.subcontract_3_outsourcing_price
        execution_rate[3] = projectInfoData.subcontract_3_execution_rate
        price[4] = projectInfoData.subcontract_4_price
        outsourcing[4] = projectInfoData.subcontract_4_outsourcing_price
        execution_rate[4] = projectInfoData.subcontract_4_execution_rate
    }

    let handleProjectInfoSubmit = async () => {
        const params = {
            contractor,
            due_date,
            contractor_price, 
            contractor_outsourcing_price,
            contractor_execution_rate,
            subcontract_0_price: price[0],
            subcontract_0_outsourcing_price: outsourcing[0],
            subcontract_0_execution_rate: execution_rate[0],
            subcontract_1_price: price[1],
            subcontract_1_outsourcing_price: outsourcing[1],
            subcontract_1_execution_rate: execution_rate[1],
            subcontract_2_price: price[2],
            subcontract_2_outsourcing_price: outsourcing[2],
            subcontract_2_execution_rate: execution_rate[2],
            subcontract_3_price: price[3],
            subcontract_3_outsourcing_price: outsourcing[3],
            subcontract_3_execution_rate: execution_rate[3],
            subcontract_4_price: price[4],
            subcontract_4_outsourcing_price: outsourcing[4],
            subcontract_4_execution_rate: execution_rate[4],
        }

        let res;
        
        projectInfoData
        ? res = await apiFetch(`project/outsourcing/info/${id}`, "put", params)
        : res = await apiFetch(`project/outsourcing/info/${id}`, "post", params);

        alert(res.data)

        if (res.state == 1) {
            handleProjectModal()
            handleProjectInfoGet()
        }
    };

</script>

<div class="modal-form">
    <div class="modal-cover"></div>
    <div class="modal">
        <i 
            class="fas fa-times modal-close"
            on:click={handleProjectModal}
        ></i>
        <div class="text-blueGray-400 text-center mb-3 font-bold">
            <p>프로젝트 정보</p>
        </div>
        <form on:submit|preventDefault={handleProjectInfoSubmit}>
            <div class="modal-form">
                <hr class="mt-6 border-b-1 border-blueGray-300" />
                <div class="text-blueGray-400 text-sm mt-3 font-bold uppercase">
                    업체 정보
                </div>
                <div class="flex">
                    <div class="modal-form w-full">
                        <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            계약업체(주관사)
                        </div>
                        <input
                            bind:value={contractor}
                            required
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                    </div>
                    <div class="modal-form w-full pl-3">
                        <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            준공예정일
                        </div>
                        <input
                            bind:value={due_date}
                            required
                            type="date"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                    </div>
                </div>
                <div class="flex">
                    <div class="modal-form w-full">
                        <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            업체 계약금액
                        </div>
                        <input
                            bind:value={contractor_price}
                            required
                            type="number" 
                            on:mousewheel={(e)=>{e.preventDefault()}}
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                    </div>
                    <div class="modal-form w-full pl-3">
                        <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            업체 외주금액
                        </div>
                        <input
                            bind:value={contractor_outsourcing_price}
                            required
                            type="number" 
                            on:mousewheel={(e)=>{e.preventDefault()}}
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                    </div>
                    <div class="modal-form w-full pl-3">
                        <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            업체 실행률
                        </div>
                        <input
                            bind:value={contractor_execution_rate}
                            required
                            type="number" 
                            on:mousewheel={(e)=>{e.preventDefault()}}
                            step="0.01"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                    </div>
                </div>
                {#if projectList[0]}
                    {#each projectList as item, index}
                    <hr class="mt-6 border-b-1 border-blueGray-300" />
                    <div class="text-blueGray-400 text-sm mt-3 font-bold uppercase">
                        {item} - 공동도급사
                    </div>
                        <div class="flex">
                            <div class="modal-form w-full">
                                <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                    계약금액
                                </div>
                                <input
                                    bind:value={price[index]}
                                    required
                                    type="number" 
                                    on:mousewheel={(e)=>{e.preventDefault()}}
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                />
                            </div>
                            <div class="modal-form w-full pl-3">
                                <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                    외주금액
                                </div>
                                <input
                                    bind:value={outsourcing[index]}
                                    required
                                    type="number" 
                                    on:mousewheel={(e)=>{e.preventDefault()}}
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                />
                            </div>
                            <div class="modal-form w-full pl-3">
                                <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                    실행률
                                </div>
                                <input
                                    bind:value={execution_rate[index]}
                                    required
                                    type="number" 
                                    on:mousewheel={(e)=>{e.preventDefault()}}
                                    step="0.01"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                />
                            </div>
                        </div>
                    {/each}
                {/if}
            </div>
            <button 
                class="bg-indigo-500 text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                style="width:100%;"
            >저장</button>
        </form>
    </div>
</div>