<script>
    import { apiFetch } from "utils/tools.js";
    export let id;
    export let getPrepaymentValue;
    export let prepayment;
    export let prepaymentIndex;
    export let handlePrepaymentModal;
    let date = new Date().toISOString().substring(0, 10),
        amount = "0", 
        note = "-";

    prepayment.forEach((item, index) => {
        if(prepayment[index]["id"] == prepaymentIndex){
            date = prepayment[index].date,
            amount = prepayment[index].amount, 
            note = prepayment[index].note;
        };
    });

    const deletePrepayment = async () => {
        
        let confirmSelect = confirm("정말 삭제하시겠습니까?");
        if(confirmSelect) {
            const res = await apiFetch(`project/prepayment/${prepaymentIndex}`, "delete")

            alert(res.data);

            if (res.state == 1) {
                getPrepaymentValue()
                handlePrepaymentModal()
            };
        };
    };

    const handlePrepaymentSubmit = async () => {
        // setType 0 = post, 1 = put
        const params = {
            prepaymentIndex,
            date,
            amount,
            note,
        };
        let res;
        
        prepaymentIndex == null
        ? res = await apiFetch(`project/prepayment/${id}`, "post", params)
        : res = await apiFetch(`project/prepayment/${id}`, "put", params);

        alert(res.data);

        if (res.state == 1) {
            getPrepaymentValue()
            handlePrepaymentModal()
        };
    };
</script>

<div class="modal-form">
    <div class="modal-cover"></div>
    <div class="modal">
        <i 
            class="fas fa-times modal-close"
            on:click={handlePrepaymentModal}
        ></i>
        <div class="text-blueGray-400 text-center mb-3 font-bold">
            <p>선금</p>
        </div>
        <form on:submit|preventDefault={handlePrepaymentSubmit}>
            <div class="modal-form">
                <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    선금 지급일
                </div>
                <input
                    bind:value={date}
                    required
                    type="date"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
            <div class="modal-form w-full">
                <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    지급 받은 금액
                </div>
                <input
                    bind:value={amount}
                    required
                    type="number" 
                    on:mousewheel={(e)=>{e.preventDefault()}}
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
            <div class="modal-form w-full">
                <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    비고
                </div>
                <input
                    bind:value={note}
                    required
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
            </div>
            <div class="flex">
                <div 
                    on:click={deletePrepayment}
                    class="bg-red-500 text-center text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    style="width:49%; cursor: pointer;"
                >
                삭제
                </div>
                <button 
                    class="bg-indigo-500 text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    style="width:49%;"
                >저장</button>
            </div>
        </form>
    </div>
</div>