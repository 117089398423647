<script>
  // make dynamic date to be added to footer
  let date = new Date().getFullYear();
</script>

<footer class="block py-4" style="margin-top: 80px;" id="printHidden">
  <div class="container px-4">
    <hr class="mb-4 border-b-1 border-blueGray-200" style="border: none;"/>
    <div class="flex flex-wrap items-center md:justify-between justify-center">
      <div class="w-full md:w-4/12 px-4">
        <div
          class="text-sm text-blueGray-500 font-semibold py-1 text-center md:text-left"
        >
          Copyright © {date}
        </div>
    </div>
  </div>
</footer>
