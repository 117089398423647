<script>
  import {getCookie} from "utils/tools.js"
  const userInfo = JSON.parse(getCookie("user"))
  
  const admins = [
    { position: "담당", auth:"7" },
    { position: "팀장", auth:"6" },
    { position: "자산구매팀장", auth:"5" },
    { position: "경영지원팀장", auth:"4" },
    { position: "총괄", auth:"3" },
    { position: "이사", auth:"2" },
    { position: "대표이사", auth:"1" },
    { position: "관리자", auth:"0" },
  ]
</script>
<nav
  id="printHidden"
  class="absolute top-0 left-0 w-full bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4"
>
  <div
    class="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4"
  >
    <a
      class="text-white text-sm uppercase hidden lg:inline-block font-semibold"
      href="#pablo"
    >
    </a>
    <ul class="flex-col md:flex-row list-none items-center md:flex header-info">
      <li>
        {userInfo.name}
        {#each admins as admin}
          {#if admin.auth == userInfo.auth}
            ({admin.position})
          {/if}
        {/each}
        님 반갑습니다!
      </li>
    </ul>
  </div>
</nav>
