<script>
    import ProjectInfoModal from 'components/Project/Direct/Modal/ProjectInfoModal.svelte';
    import { onMount } from 'svelte';
    import { apiFetch } from 'utils/tools.js'
    import { directTotalAmount } from '../../../store/stores';
    


    export let id;
    export let thisProject;
    
    const projectColumns = ['계약업체', '준공예정일', '계약금액', '기성(준공)금액', '실행금액', '예정실행율',  '집행금액(직접비)', '집행율', '실행금액\n(본사관리비포함)', '본사관리비', '실행율', '당초실행대비', '공정율'];
    
    let items;
    let expectRate = 0      // 예상실행률
    let executionRate = 0   // 집행률
    let practiceRate = 0    // 실행률
    let compareRate = 0     // 당초 실행 대비
    let totalAmount = 0     // 집행금액
    let executionAmount = 0 // 실행금액(본사 관리비 포함)

    directTotalAmount.subscribe(value => {
        totalAmount = value
    })

    $: totalAmount, handleProjectInfoGet()

    let setProjectModal = false;

    const handleProjectModal = () => {
        setProjectModal = !setProjectModal;
    }

    const handleProjectInfoGet = async () => {
        [items] = await apiFetch(`project/direct/info/${id}`)
        if(totalAmount) {
            executionAmount = Math.floor(totalAmount + items.contract_amount * items.management_rate/100)
            expectRate = ((items.execution_amount/items.contract_amount)*100).toFixed(2)
            executionRate = ((totalAmount/items.completion_amount)*100).toFixed(2)
            practiceRate = ((executionAmount/items.completion_amount)*100).toFixed(2)
            compareRate = ((executionRate/expectRate)*100).toFixed(2)
            
            if(!isFinite(expectRate)) expectRate = 0; 
            if(!isFinite(executionRate)) executionRate = 0; 
            if(!isFinite(practiceRate)) practiceRate = 0; 
            if(!isFinite(compareRate)) compareRate = 0; 
        }

    }

</script>
<div style="display: flex; justify-content: space-between; align-items: center;">
    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold">
        프로젝트 정보
    </h6>
    {#if thisProject && thisProject.state == 1}
        <button 
            class="bg-indigo-500 text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
            type="button"
            style="height: fit-content;"
            on:click={handleProjectModal}
        >

            {items ? "수정" : "추가"}
        </button>
    {/if}
</div>

<div class="project-info-wrap" style="max-width: max-content;">
    <table class="project-info">
        <thead class="project-info-header">
            <tr>
                {#each projectColumns as column}
                <th>{column}</th>
                {/each}
            </tr>
        </thead>
        <tbody class="project-info-body">
            <tr>
                {#if !items}
                    <td colspan="12">데이터가 없습니다.</td>
                {:else} 
                    <td>{items.contractor.toLocaleString()}</td>
                    <td>{items.due_date.toLocaleString()}</td>
                    <td>{items.contract_amount.toLocaleString()}</td>
                    <td>{items.completion_amount.toLocaleString()}</td>
                    <td>{items.execution_amount.toLocaleString()}</td>
                    <td>{expectRate}%</td>
                    <td>{totalAmount.toLocaleString()}</td>
                    <td>{executionRate}%</td>
                    <td>{executionAmount.toLocaleString()}</td>
                    <td>{items.management_rate}%</td>
                    <td>{practiceRate}%</td>
                    <td>{compareRate}%</td>
                    <td>{items.process_rate.toLocaleString()}%</td>
                {/if}
            </tr>
        </tbody>
    </table>
</div>

{#if setProjectModal}
    <ProjectInfoModal {handleProjectModal} {handleProjectInfoGet} {id} {items}/>
{/if}