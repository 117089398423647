<script>
  export let title;
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-blueGray-700 text-xl font-bold">{title}</h6>

    </div>
  </div>
  <div id="printArea" class="flex-auto px-4 lg:px-10 py-10 pt-0" style="margin: 20px 0 40px 0;">
    <slot />
  </div>
</div>