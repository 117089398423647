export const data_line = [
    {valueX: 1, valueY: 0.0},
    {valueX: 2, valueY: 0.0},
];

export const data_fall = [
    {valueX: 1, valueY: 0.0},
    {valueX: 2, valueY: 0.0},
    {valueX: 3, valueY: 0.0},
    {valueX: 4, valueY: 0.0},
    {valueX: 5, valueY: 0.0},
    {valueX: 6, valueY: 1.0},
    {valueX: 7, valueY: 1.0},
    {valueX: 8, valueY: 1.0},

];

export const data_rise =  [
    {valueX: 1, valueY: 1.0},
    {valueX: 2, valueY: 1.0},
    {valueX: 3, valueY: 1.0},
    {valueX: 4, valueY: 1.0},
    {valueX: 5, valueY: 1.0},
    {valueX: 6, valueY: 0.0},
    {valueX: 7, valueY: 0.0},
    {valueX: 8, valueY: 0.0},
];
