<script>    
    import { apiFetch, getCookie } from "utils/tools.js"
    import Approval from 'components/Project/Approval.svelte';
    import ApprovalTable from 'components/Project/ApprovalTable.svelte';
    import ProjectInfo from "components/Project/Direct/ProjectInfo.svelte";
    import VAT from "components/Project/Direct/VAT.svelte";
    import Prepayment from "components/Project/Prepayment.svelte";
    import History from "components/Project/Direct/History.svelte";
    import { navigate } from "svelte-routing";

    export let id;
    export let projects;
    export let userInfo;

    let level_DownSide = 0
    let assetsState = 0
    let managementState = 0
    let done = 0
    let thisProject;
    let history

    let userData = JSON.parse(getCookie("user"))

    $: level_DownSide, assetsState, managementState, done, latestProject;
    $: projects, getThisProject();
    let latestProject

    const getHistory = async () => {
        history = await apiFetch(`project/direct/history/${id}`)
        return history
    }

    const getThisProject = async () => {
        if(id) {
            latestProject = await apiFetch(`project/direct/history/latest/${id}`);
            latestProject = latestProject[0]
            if(latestProject){
                level_DownSide = 0
                assetsState = latestProject["assets_leader"]
                managementState = latestProject["management_leader"]
                done = latestProject["ceo"]

                if(latestProject["manager"] !== 0) level_DownSide += 1;
                if(latestProject["leader"] !== 0) level_DownSide += 1;
                if(latestProject["general"] !== 0) level_DownSide += 1;
                if(latestProject["coo"] !== 0) level_DownSide += 1;
            }
            
            await projects.then((items) => {
                items.forEach((item, index)=>{
                    if(item.id == id) {
                        thisProject = item
                    }
                })
            });
        }
    }

    const handleProjectEnd = async () => {
        const checkConfirm = confirm("프로젝트를 종료하시겠습니까?");

        if(checkConfirm) {
            let res = await apiFetch(`project/direct/end/${id}`, "PATCH");
            alert(res.data)

            if(res.state == 1) {
                navigate(`/`, { replace: true });
            }
        };
    }

    const handleProjectReset = async () => {
        const checkConfirm = confirm("프로젝트를 복구하시겠습니까?");

        if(checkConfirm) {
            let res = await apiFetch(`project/direct/reset/${id}`, "PATCH");
            alert(res.data)

            if(res.state == 1) {
                navigate(`/`, { replace: true });
            }
        };
    }

    const handlePaymentSubmit = async () => {
        const checkConfirm = confirm("결재를 하시겠습니까?");

        if(checkConfirm) {
            let res = await apiFetch(`project/direct/history/approval/${id}`, "PATCH");
            alert(res.data)

            if(res.state == 1) {
                [latestProject] = await apiFetch(`project/direct/history/latest/${id}`);
                getHistory()
                level_DownSide = 0
                assetsState = latestProject["assets_leader"]
                managementState = latestProject["management_leader"]
                done = latestProject["ceo"]

                if(latestProject["manager"] !== 0) level_DownSide += 1;
                if(latestProject["leader"] !== 0) level_DownSide += 1;
                if(latestProject["general"] !== 0) level_DownSide += 1;
                if(latestProject["coo"] !== 0) level_DownSide += 1;
            }
        };
    }
</script>

<hr class="mt-6 border-b-1 border-blueGray-300" />
<div id="printHidden">
    <Approval {assetsState} {managementState} {level_DownSide} {done} steps_TopSide={['자산구매팀장','경영지원팀장']} steps_DownSide={['담당','팀장','총괄','이사']} steps_Last={'대표이사'}  />
</div>
<ApprovalTable {latestProject} {id}/>
<hr class="mt-6 border-b-1 border-blueGray-300" />
<ProjectInfo {id} {history} {thisProject} {latestProject}/>
<hr class="mt-6 border-b-1 border-blueGray-300" />
<VAT {id} {history} {getHistory}/>
<hr class="mt-6 border-b-1 border-blueGray-300" />
<Prepayment {id}/>
<hr class="mt-6 border-b-1 border-blueGray-300" />
<History {projects} {id} {thisProject} {latestProject} {history} {getHistory}/>
{#if thisProject && thisProject.state == 1}
        <button 
            id="printHidden"
            on:click={handlePaymentSubmit}
            class="w-full bg-indigo-500 text-white active:bg-indigo-600 p-3 mt-10 font-bold uppercase align-bottom rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        >
        결재
        </button>
        {#if userInfo.auth <= "3"}
            <button 
                id="printHidden"
                on:click={handleProjectEnd}
                class="w-full bg-red-500 text-white active:bg-indigo-600 p-3 mt-3 font-bold uppercase align-bottom rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            >
            프로젝트 종료
            </button>
        {/if}
{:else}
    <button 
        id="printHidden"
        class="w-full bg-gray-200 text-white  p-3 mt-3 font-bold uppercase align-bottom rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
    >
    종료된 프로젝트 입니다.
    </button>

    {#if Number(userData.auth) <= 3}
    <button 
        id="printHidden"
        on:click={handleProjectReset}
        class="w-full bg-red-500 text-white active:bg-indigo-600 p-3 mt-3 font-bold uppercase align-bottom rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
    >
    프로젝트 복구
    </button>
    {/if}
{/if}