<script>
    import Card from "../layouts/CardLayout.svelte";
    import ProjectRegister from "components/ProjectRegister/Register.svelte";

    const title = "프로젝트 추가"

    const divisions = [
        { id: 1, name: `직영` },
        { id: 2, name: `외주` },
    ];

</script>

<Card {title} >
    <ProjectRegister {divisions}/>
</Card>