<script>
    import { getCookie } from "utils/tools.js";
    import { apiFetch } from "utils/tools.js";
    export let latestProject;
    export let id;
    
    const position = ["", "자산구매팀장", "경영지원팀장", "담당", "팀장", "총괄", "이사", "대표이사"]
    const approvalList = ["미결", "대표이사", "이사", "총괄", "팀장", "담당"]
    let approvedInfo = []
    let approvedDateList = []

    let list = []
    $: latestProject, projectSituation();
    const projectSituation = () => {
        if(latestProject) {
            approvedInfo = [
                latestProject.assets_info, 
                latestProject.management_info, 
                latestProject.manager_info, 
                latestProject.leader_info, 
                latestProject.general_info, 
                latestProject.coo_info, 
                latestProject.ceo_info
            ]
            approvedDateList = [
                latestProject.assets_approval_date, 
                latestProject.management_approval_date, 
                latestProject.manager_approval_date, 
                latestProject.leader_approval_date, 
                latestProject.general_approval_date, 
                latestProject.coo_approval_date, 
                latestProject.ceo_approval_date
            ]
            list = [
                latestProject.manager,
                latestProject.leader,
                latestProject.general,
                latestProject.coo,
                latestProject.ceo
            ]
        }
    }

    let userInfo;
    if(getCookie("user")) {
        userInfo = JSON.parse(getCookie("user"))
    }

    const rejectApproval = async (auth) => {
        let confirmSelect = confirm("결재를 반려하시겠습니까?");
        if(confirmSelect) {
            const params = {
                auth
            }
            const response = await apiFetch(`project/reject/${id}`, "PATCH", params)
            if(response.state == 1) {
                [latestProject] = await apiFetch(`project/${response.division}/history/latest/${id}`);
            }
            alert(response.data)   
        }
    }

</script>

<div class="project-info-wrap" style="max-width: max-content; margin-top: 30px;">
    <table class="project-info">
        <thead class="project-info-header">
            <tr>
                {#each position as item}
                    <th style="min-width:110px;">{item}</th>
                {/each}
            </tr>
        </thead>
        <tbody class="project-info-body">
            {#if latestProject}
                <tr>
                    <td>결재</td>
                    <td
                    style="color: {latestProject.assets_leader == 0 ? "" : "#3a57ff"};"
                    >
                        {latestProject.assets_leader == 0 ? "미결" : "자산구매팀장"}
                    </td>
                    <td
                        style="color: {latestProject.management_leader == 0 ? "": "#3a57ff"};"
                    >
                        {latestProject.management_leader == 0 ? "미결" : "경영지원팀장"}
                    </td>
                    {#each list as item, index}
                    <td 
                        style="{
                            approvalList[item] == "미결" 
                            ? "" 
                            : item !== (5 - index)
                            ? "color: #ff3a3a;" 
                            : "color: #3a57ff;"
                        }">
                        {approvalList[item]}
                    </td>
                    {/each}
                </tr>
                <tr>
                    <td>결재자</td>
                    {#each approvedInfo as item}
                        <td>{item == null ? "-" : item}</td>
                    {/each}
                </tr>
                <tr>
                    <td>결재일</td>
                    {#each approvedDateList as item}
                        <td>{item == null ? "-" : item}</td>
                    {/each}
                </tr>
                
                {#if userInfo.auth < 4 || userInfo.auth == 6}
                    <tr>
                        <td>결재 반려</td>
                        <td style="color: #ff0000;">
                            <p 
                                style="cursor: pointer;"
                                on:click={latestProject.assets_leader == 0 ? undefined : rejectApproval(6)}>
                                {latestProject.assets_leader == 0 ? "-" : "반려"}
                            </p>
                        </td>
                        <td style="color: #ff0000;">
                            <p
                                style="cursor: pointer;"
                                on:click={latestProject.management_leader == 0  ? undefined : rejectApproval(5)}>
                                {latestProject.management_leader == 0 ? "-" : "반려"}
                            </p>
                        </td>
                        {#each list as item, index}
                            <td 
                                style="color: #ff0000;"
                            >
                                <p 
                                    style="cursor: pointer;"
                                    on:click={approvalList[item] == "미결" ? undefined : rejectApproval(index)}>
                                    {approvalList[item] == "미결" ? "-" : "반려"}
                                </p>
                            </td>
                        {/each}
                    </tr>
                {/if}  
            {:else }
            <tr>
                <td>결재</td>
                <td colspan="7">정산 내역을 등록해주세요.</td>
            </tr> 
            {/if}
        </tbody>
    </table>
</div>