<script>
    import { apiFetch, getCookie } from "utils/tools.js"
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import DirectProject from "components/Project/DirectProject.svelte";
    import OutsourcingProject from "components/Project/OutsourcingProject.svelte";

    export let id;
    let division = 1;
    let project = []

    const userInfo = JSON.parse(getCookie("user"))
    
    $: id;
    $: teams = apiFetch("team");
    $: projects = apiFetch("project");
 
    onMount( async ()=>{
        if (id) {
            [project] = await apiFetch(`project/${id}`);
            division = project.division
            setTimeout(()=>{
                window.print();
                navigate(`/project/${id}`, { replace: true });
            }, 500)
        }else {
            alert("잘못된 접근 방법입니다.")
            navigate(`/`, { replace: true });
        }
    })

</script>

{#if id}
    <div class="print-page-wrap">
        <h3 style="font-size: 40px; text-align:center;">{project.name}</h3>
        {#if division == 1}
            <DirectProject {id} {projects} {userInfo}/>
        {:else if division == 2}
            <OutsourcingProject {id} {projects} {userInfo}/>
        {/if}
    </div>
{/if}
