<script>
    import { apiFetch } from "utils/tools.js"
    export let items;
    export let handleTeamModal;

    const handleTeamDelete = async (setIndex) => {
        let confirmSelect = confirm("정말 삭제하시겠습니까?");
        if(confirmSelect) {
            const data = await apiFetch("team", "delete", {id: setIndex});
            alert(data.data)

            if (data.state == '1'){
                document.querySelector(`#table${setIndex}`).style.display = "none";
            }
        }
    }
</script>

<div class="project-info-wrap">
    <table  class="project-info" style="width: 100%;">
        <thead class="project-info-header">
        <tr>
            <th>번호</th>
            <th>팀</th>
            <th>팀장</th>
            <th>담당</th>
            <th>관리</th>
            <th>삭제</th>
        </tr>
        </thead>
        <tbody class="project-info-body">
            {#await items}
            <tr>
                <td colspan="6">Loading</td>
            </tr>
            {:then items } 
            {#if !items[0]}
                <tr>
                <td colspan="7">데이터가 없습니다.</td>
                </tr>
            {/if}
            {#each items as item, index}
            <tr id="table{item.id}">
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>              
                    {#if item.leader[0]}
                        {#each item.leader as list}
                            {list}
                        {/each}
                    {:else}
                        -
                    {/if}
                </td>
                <td>
                    {#if item.manager[0]}
                        {#each item.manager as list}
                            <p>{list}</p>
                        {/each}
                    {:else}
                        -
                    {/if}
                </td>
                <td class="text-center">
                <button  
                    class="align-middle text-center bg-indigo-500 text-white active:bg-indigo-600  font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button" 
                    on:click={() => {handleTeamModal(item.id)}}
                >
                    관리
                </button>
                </td>
                <td class="text-center">
                    <button  
                    class="align-middle text-center bg-red-500 text-white active:bg-red-300  font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button" 
                    on:click={() => {handleTeamDelete(item.id)}}
                    >
                    삭제
                    </button>
                </td>
            </tr>
            {/each}
            {:catch error}
            <tr>
            <td colspan="7">오류가 발생했습니다.</td>
            </tr>
            {/await}
        </tbody>
    </table>
</div>