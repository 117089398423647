
<script>
    import HistoryModal from 'components/Project/Outsourcing/Modal/HistoryModal.svelte';
    import LogModal from 'components/Project/Outsourcing/Modal/LogModal.svelte';
    import { onMount } from "svelte";
    import { apiFetch, getCookie } from "utils/tools.js"

    export let id;
    export let projects;
    export let history;
    export let handleHistoryGet;
    export let thisProject;

    const userInfo = JSON.parse(getCookie("user"))

    const admins = [
        { position: "manager", auth:"7" },
        { position: "leader", auth:"6" },
        { position: "assets_leader", auth:"5" },
        { position: "management_leader", auth:"4" },
        { position: "general", auth:"3" },
        { position: "coo", auth:"2" },
        { position: "ceo", auth:"1" },
    ]

    let done_original_contract = 0, 
        done_receive_amount = 0, 
        done_outsoucing_execution_possible_price = 0,
        done_material = 0, 
        done_material_ledger = 0,
        done_material_tax = 0, 
        done_material_receipt = 0, 
        done_material_subtotal = 0, 
        done_labor_office = 0, 
        done_labor_technical = 0, 
        done_labor_daily = 0, 
        done_labor_subtotal = 0, 
        done_expense_tax = 0, 
        done_expense_safety = 0, 
        done_expense_receipt = 0, 
        done_expense_subtotal = 0, 
        done_our_total = 0, 
        done_our_non_payment = 0, 
        done_subcontract_0_labor = 0, 
        done_subcontract_0_tax = 0, 
        done_subcontract_0_subtotal = 0, 
        done_subcontract_1_labor = 0, 
        done_subcontract_1_tax = 0, 
        done_subcontract_1_subtotal = 0, 
        done_subcontract_2_labor = 0, 
        done_subcontract_2_tax = 0, 
        done_subcontract_2_subtotal = 0, 
        done_subcontract_3_labor = 0, 
        done_subcontract_3_tax = 0, 
        done_subcontract_3_subtotal = 0, 
        done_subcontract_4_labor = 0, 
        done_subcontract_4_tax = 0, 
        done_subcontract_4_subtotal = 0, 
        done_sub_total = 0, 
        done_total = 0,
        done_outsorucing_our_rental = 0,
        done_outsourcing_subcontract_rental = 0,
        proceed_original_contract = 0, 
        proceed_receive_amount = 0, 
        proceed_outsoucing_execution_possible_price = 0,
        proceed_material = 0, 
        proceed_material_ledger = 0,
        proceed_material_tax = 0, 
        proceed_material_receipt = 0, 
        proceed_material_subtotal = 0, 
        proceed_labor_office = 0, 
        proceed_labor_technical = 0, 
        proceed_labor_daily = 0, 
        proceed_labor_subtotal = 0, 
        proceed_expense_tax = 0, 
        proceed_expense_safety = 0, 
        proceed_expense_receipt = 0, 
        proceed_expense_subtotal = 0, 
        proceed_our_total = 0, 
        proceed_our_non_payment = 0, 
        proceed_subcontract_0_labor = 0, 
        proceed_subcontract_0_tax = 0, 
        proceed_subcontract_0_subtotal = 0, 
        proceed_subcontract_1_labor = 0, 
        proceed_subcontract_1_tax = 0, 
        proceed_subcontract_1_subtotal = 0, 
        proceed_subcontract_2_labor = 0, 
        proceed_subcontract_2_tax = 0, 
        proceed_subcontract_2_subtotal = 0, 
        proceed_subcontract_3_labor = 0, 
        proceed_subcontract_3_tax = 0, 
        proceed_subcontract_3_subtotal = 0, 
        proceed_subcontract_4_labor = 0, 
        proceed_subcontract_4_tax = 0, 
        proceed_subcontract_4_subtotal = 0, 
        proceed_sub_total = 0, 
        proceed_total = 0,
        proceed_outsorucing_our_rental = 0,
        proceed_outsourcing_subcontract_rental = 0;

    let subcontractList = [];
    let historyDone = [];
    let historyProceed = [];

    let moreButton = false;
    let setHistoryModal = false;
    let logModal = false;
    let logIndex;
    let historyIndexData;

    const handelSubcontractList = async () => {
        let items = await projects
        items.forEach((item, index)=>{
            if(item.id == id) {
                for(let i = 0; i < 5; i++) {
                    if(item[`subcontract_${i}`]) {
                        subcontractList = [...subcontractList, item[`subcontract_${i}`]]
                    }
                }
            }
        })
    }

    const handleHistory = async (reload) => {
        if(reload == 1) await handleHistoryGet();
        handelMoreList()

        done_original_contract = 0, 
        done_receive_amount = 0, 
        done_outsoucing_execution_possible_price = 0,
        done_material = 0, 
        done_material_ledger = 0,
        done_material_tax = 0, 
        done_material_receipt = 0, 
        done_material_subtotal = 0, 
        done_labor_office = 0, 
        done_labor_technical = 0, 
        done_labor_daily = 0, 
        done_labor_subtotal = 0, 
        done_expense_tax = 0, 
        done_expense_safety = 0, 
        done_expense_receipt = 0, 
        done_expense_subtotal = 0, 
        done_our_total = 0, 
        done_our_non_payment = 0, 
        done_subcontract_0_labor = 0, 
        done_subcontract_0_tax = 0, 
        done_subcontract_0_subtotal = 0, 
        done_subcontract_1_labor = 0, 
        done_subcontract_1_tax = 0, 
        done_subcontract_1_subtotal = 0, 
        done_subcontract_2_labor = 0, 
        done_subcontract_2_tax = 0, 
        done_subcontract_2_subtotal = 0, 
        done_subcontract_3_labor = 0, 
        done_subcontract_3_tax = 0, 
        done_subcontract_3_subtotal = 0, 
        done_subcontract_4_labor = 0, 
        done_subcontract_4_tax = 0, 
        done_subcontract_4_subtotal = 0, 
        done_sub_total = 0, 
        done_total = 0,
        done_outsorucing_our_rental = 0,
        done_outsourcing_subcontract_rental = 0,
        proceed_original_contract = 0, 
        proceed_receive_amount = 0, 
        proceed_outsoucing_execution_possible_price = 0,
        proceed_material = 0, 
        proceed_material_ledger = 0,
        proceed_material_tax = 0, 
        proceed_material_receipt = 0, 
        proceed_material_subtotal = 0, 
        proceed_labor_office = 0, 
        proceed_labor_technical = 0, 
        proceed_labor_daily = 0, 
        proceed_labor_subtotal = 0, 
        proceed_expense_tax = 0, 
        proceed_expense_safety = 0, 
        proceed_expense_receipt = 0, 
        proceed_expense_subtotal = 0, 
        proceed_our_total = 0, 
        proceed_our_non_payment = 0, 
        proceed_subcontract_0_labor = 0, 
        proceed_subcontract_0_tax = 0, 
        proceed_subcontract_0_subtotal = 0, 
        proceed_subcontract_1_labor = 0, 
        proceed_subcontract_1_tax = 0, 
        proceed_subcontract_1_subtotal = 0, 
        proceed_subcontract_2_labor = 0, 
        proceed_subcontract_2_tax = 0, 
        proceed_subcontract_2_subtotal = 0, 
        proceed_subcontract_3_labor = 0, 
        proceed_subcontract_3_tax = 0, 
        proceed_subcontract_3_subtotal = 0, 
        proceed_subcontract_4_labor = 0, 
        proceed_subcontract_4_tax = 0, 
        proceed_subcontract_4_subtotal = 0, 
        proceed_sub_total = 0, 
        proceed_total = 0,
        proceed_outsorucing_our_rental = 0,
        proceed_outsourcing_subcontract_rental = 0;

        historyDone = [];
        historyProceed = [];
        history["main"].forEach((item, index) => {
            if(item.ceo == 1){
                historyDone = [...historyDone, item]
            } 
        })
        history["sub"].forEach((item, index) => {
            historyProceed = [...historyProceed, item];
        })

        historyDone.forEach((item, index) => {
            done_original_contract += parseInt(item["original_contract"])
            done_receive_amount += parseInt(item["receive_amount"])
            done_outsoucing_execution_possible_price += parseInt(item["outsoucing_execution_possible_price"])
            done_material += parseInt(item["material"])
            done_material_ledger += parseInt(item["material_ledger"])
            done_material_tax += parseInt(item["material_tax"])
            done_material_receipt += parseInt(item["material_receipt"])
            done_material_subtotal += parseInt(item["material"]) + parseInt(item["material_ledger"]) + parseInt(item["material_tax"]) + parseInt(item["material_receipt"])
            done_labor_office += parseInt(item["labor_office"])
            done_labor_technical += parseInt(item["labor_technical"])
            done_labor_daily += parseInt(item["labor_daily"])
            done_labor_subtotal += parseInt(item["labor_office"]) + parseInt(item["labor_technical"]) + parseInt(item["labor_daily"])
            done_expense_tax += parseInt(item["expense_tax"])
            done_expense_safety += parseInt(item["expense_safety"])
            done_expense_receipt += parseInt(item["expense_receipt"])
            done_expense_subtotal += parseInt(item["expense_tax"]) + parseInt(item["expense_safety"]) + parseInt(item["expense_receipt"])
            done_our_non_payment += parseInt(item["our_non_payment"])
            done_subcontract_0_labor += parseInt(item["subcontract_0_labor"])
            done_subcontract_0_tax += parseInt(item["subcontract_0_tax"])
            done_subcontract_0_subtotal += parseInt(item["subcontract_0_labor"]) + parseInt(item["subcontract_0_tax"])
            done_subcontract_1_labor += parseInt(item["subcontract_1_labor"])
            done_subcontract_1_tax += parseInt(item["subcontract_1_tax"])
            done_subcontract_1_subtotal += parseInt(item["subcontract_1_labor"]) + parseInt(item["subcontract_1_tax"])
            done_subcontract_2_labor += parseInt(item["subcontract_2_labor"])
            done_subcontract_2_tax += parseInt(item["subcontract_2_tax"])
            done_subcontract_2_subtotal += parseInt(item["subcontract_2_labor"]) + parseInt(item["subcontract_2_tax"])
            done_subcontract_3_labor += parseInt(item["subcontract_3_labor"])
            done_subcontract_3_tax += parseInt(item["subcontract_3_tax"])
            done_subcontract_3_subtotal += parseInt(item["subcontract_3_labor"]) + parseInt(item["subcontract_3_tax"])
            done_subcontract_4_labor += parseInt(item["subcontract_4_labor"])
            done_subcontract_4_tax += parseInt(item["subcontract_4_tax"])
            done_subcontract_4_subtotal += parseInt(item["subcontract_4_labor"]) + parseInt(item["subcontract_4_tax"])
            done_outsorucing_our_rental += parseInt(item["outsorucing_our_rental"])
            done_outsourcing_subcontract_rental += parseInt(item["outsourcing_subcontract_rental"])
        })
        done_our_total = done_material_subtotal + done_labor_subtotal + done_expense_subtotal
        done_sub_total = done_subcontract_0_subtotal + done_subcontract_1_subtotal + done_subcontract_2_subtotal + done_subcontract_3_subtotal + done_subcontract_4_subtotal;
        done_total = done_our_total + done_sub_total
        historyProceed.forEach((item, index) => {
            proceed_original_contract += parseInt(item["original_contract"])
            proceed_receive_amount += parseInt(item["receive_amount"])
            proceed_outsoucing_execution_possible_price += parseInt(item["outsoucing_execution_possible_price"])
            proceed_material += parseInt(item["material"])
            proceed_material_ledger += parseInt(item["material_ledger"])
            proceed_material_tax += parseInt(item["material_tax"])
            proceed_material_receipt += parseInt(item["material_receipt"])
            proceed_material_subtotal += parseInt(item["material"]) + parseInt(item["material_ledger"]) + parseInt(item["material_tax"]) + parseInt(item["material_receipt"])
            proceed_labor_office += parseInt(item["labor_office"])
            proceed_labor_technical += parseInt(item["labor_technical"])
            proceed_labor_daily += parseInt(item["labor_daily"])
            proceed_labor_subtotal += parseInt(item["labor_office"]) + parseInt(item["labor_technical"]) + parseInt(item["labor_daily"])
            proceed_expense_tax += parseInt(item["expense_tax"])
            proceed_expense_safety += parseInt(item["expense_safety"])
            proceed_expense_receipt += parseInt(item["expense_receipt"])
            proceed_expense_subtotal += parseInt(item["expense_tax"]) + parseInt(item["expense_safety"]) + parseInt(item["expense_receipt"])
            proceed_our_non_payment += parseInt(item["our_non_payment"])
            proceed_subcontract_0_labor += parseInt(item["subcontract_0_labor"])
            proceed_subcontract_0_tax += parseInt(item["subcontract_0_tax"])
            proceed_subcontract_0_subtotal += parseInt(item["subcontract_0_labor"]) + parseInt(item["subcontract_0_tax"])
            proceed_subcontract_1_labor += parseInt(item["subcontract_1_labor"])
            proceed_subcontract_1_tax += parseInt(item["subcontract_1_tax"])
            proceed_subcontract_1_subtotal += parseInt(item["subcontract_1_labor"]) + parseInt(item["subcontract_1_tax"])
            proceed_subcontract_2_labor += parseInt(item["subcontract_2_labor"])
            proceed_subcontract_2_tax += parseInt(item["subcontract_2_tax"])
            proceed_subcontract_2_subtotal += parseInt(item["subcontract_2_labor"]) + parseInt(item["subcontract_2_tax"])
            proceed_subcontract_3_labor += parseInt(item["subcontract_3_labor"])
            proceed_subcontract_3_tax += parseInt(item["subcontract_3_tax"])
            proceed_subcontract_3_subtotal += parseInt(item["subcontract_3_labor"]) + parseInt(item["subcontract_3_tax"])
            proceed_subcontract_4_labor += parseInt(item["subcontract_4_labor"])
            proceed_subcontract_4_tax += parseInt(item["subcontract_4_tax"])
            proceed_subcontract_4_subtotal += parseInt(item["subcontract_4_labor"]) + parseInt(item["subcontract_4_tax"])
            proceed_outsorucing_our_rental += parseInt(item["outsorucing_our_rental"])
            proceed_outsourcing_subcontract_rental += parseInt(item["outsourcing_subcontract_rental"])
        })
        proceed_our_total = proceed_material_subtotal + proceed_labor_subtotal + proceed_expense_subtotal
        proceed_sub_total = proceed_subcontract_0_subtotal + proceed_subcontract_1_subtotal + proceed_subcontract_2_subtotal + proceed_subcontract_3_subtotal + proceed_subcontract_4_subtotal;
        proceed_total = proceed_our_total + proceed_sub_total
    }



    const handleHistoryModal = (index = null) => {

        let managerAuth = null;
        admins.forEach((item, index) => {
            if( item.auth == userInfo.auth ) {
                managerAuth = item.position
            }
        })

        if ( history["main"][history["main"].length-1] && history["main"][history["main"].length-1]["ceo"] == 0 && history["main"][history["main"].length-1][managerAuth] !== 0 ){
            return alert("결재 후 추가 및 수정이 불가능합니다.")
        }
       
        if (!index) {
            historyIndexData = undefined
        } else {
            history["sub"].forEach((item) => {
                if (item.id == index) {
                    historyIndexData = item
                }
            })
        }
        setHistoryModal = !setHistoryModal;
    }

    const handleLogModal = async (index = null) => {

        logIndex = index;
        logModal = !logModal;
    }

    const handelMoreList = () => {
        const hiddenElements = document.querySelectorAll(".history-hidden");
        moreButton = !moreButton
        if (!moreButton) {
            hiddenElements.forEach((item) => {          
                item.classList.remove("active")
            })
        } else {
            hiddenElements.forEach((item) => {          
                item.classList.add("active")
            })
        }
    };

    const handleDeleteHistory = async (index) => {

        let managerAuth = null;
        admins.forEach((item, index) => {
            if( item.auth == userInfo.auth ) {
                managerAuth = item.position
            }
        })

        if ( 
            history["main"][history["main"].length-1] 
            && history["main"][history["main"].length-1]["ceo"] == 0 
            && history["main"][history["main"].length-1][managerAuth] !== 0 
        ){
            return alert("결재 후 프로젝트 삭제는 불가능합니다.")
        }

        let confirmSelect = confirm("정말 삭제하시겠습니까?");
        if(confirmSelect) {
            const response = await apiFetch(`project/outsourcing/history/proceed/${index}`, "DELETE")
            if(response.state == 1) {
                handleHistory(1);
            }
            alert(response.data)   
        }
    }

    onMount(()=>{
        handleHistory()
        handelSubcontractList()
    })
    $: history, handleHistory();
    $: subcontractList, historyIndexData;

</script>
    <div style="display: flex; align-items: center;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold">
            정산 내역
        </h6>
        <button 
            class="{subcontractList.length !== 0 ? "bg-teal-500" : "bg-gray-200"} text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
            type="button"
            style="height: fit-content; margin-left: auto; "
            on:click={subcontractList.length !== 0 ? handelMoreList : ""}
        >

            {subcontractList.length !== 0  ? (moreButton ? "숨기기" : "더보기") : "공동도급사 없음"}

        </button>
        {#if thisProject && thisProject.state == 1}
            <button 
                on:click={handleHistoryModal}
                class="bg-indigo-500 text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                type="button"
                style="height: fit-content;"
            >
            추가
            </button>
        {/if}
    </div>
    <div class="history-info-wrap">
        <table class="history-info">
            <thead class="history-info-header">
                <tr>
                    <th rowspan="3">정산일</th>
                    <th rowspan="2" colspan="3">기성금액</th>
                    <th colspan="12">당사 현장매입자료(자재비, 노무비, 경비)</th>
                    <th rowspan="3">합계</th>
                    <th rowspan="3">미지급금액</th>
                    {#if subcontractList.length !== 0}
                        <th class="history-hidden" colspan="{subcontractList.length*3}">공동도급사 집행금액</th>
                        <th class="history-hidden" rowspan="3">합계</th>
                    {/if}
                    <th rowspan="3">총합계</th>
                    <th rowspan="3">외주업체<br><i class="fas fa-sort-down"></i><br>당사<br>대여금액</th>
                    <th rowspan="3">외주업체<br><i class="fas fa-sort-down"></i><br>공동도급사<br>대여금액</th>
                    <th rowspan="3">비고</th>
                    <th id="printHidden" rowspan="3">수정</th>
                    <th id="printHidden" rowspan="3" style="border-right: none;">기록</th>
                </tr>
                <tr>
                    <th colspan="5">자재비</th>
                    <th colspan="3">노무비</th>
                    <th colspan="4">경비</th>
                    {#each subcontractList as list}
                        <th class="history-hidden" colspan="3">{list}</th>
                    {/each}
                </tr>
                <tr>
                    <th>당사기성금액</th>
                    <th>공동도급사<br>기성급액(당사분)</th>
                    <th>외주집행가능금액</th>
                    <th>계산서</th>
                    <th>영수증</th>
                    <th>세금계산서</th>
                    <th>카드</th>
                    <th>소계</th>
                    <th>상용</th>
                    <th>일용</th>
                    <th>소계</th>
                    <th>세금계산서</th>
                    <th>안전관리비</th>
                    <th>카드,영수증</th>
                    <th>소계</th>
                    {#each subcontractList as list}
                        <th class="history-hidden" >노무비•경비</th>
                        <th class="history-hidden" >세금계산서</th>
                        <th class="history-hidden">소계</th>
                    {/each}
                </tr>
            </thead>
            <tbody class="history-info-body">
                {#if history["main"][0]}
                    {#if historyDone[0]}
                        {#each historyDone as historyData}
                            <tr id="printHidden">
                                <td>{historyData.calculate_date}</td>
                                <td class="history-emphasisA">{historyData.original_contract.toLocaleString()}</td>
                                <td class="history-emphasisA">{historyData.receive_amount.toLocaleString()}</td>
                                <td class="history-emphasisA">{historyData.outsoucing_execution_possible_price.toLocaleString()}</td>
                                <td>{historyData.material.toLocaleString()}</td>
                                <td>{historyData.material_ledger.toLocaleString()}</td>
                                <td>{historyData.material_tax.toLocaleString()}</td>
                                <td>{historyData.material_receipt.toLocaleString()}</td>
                                <td class="history-emphasisB">{(historyData.material + historyData.material_ledger + historyData.material_tax + historyData.material_receipt).toLocaleString()}</td>
                                <!-- <td>{historyData.labor_office.toLocaleString()}</td> -->
                                <td>{(historyData.labor_office+historyData.labor_technical).toLocaleString()}</td>
                                <td>{historyData.labor_daily.toLocaleString()}</td>
                                <td class="history-emphasisB">{(historyData.labor_office + historyData.labor_technical + historyData.labor_daily).toLocaleString()}</td>
                                <td>{historyData.expense_tax.toLocaleString()}</td>
                                <td>{historyData.expense_safety.toLocaleString()}</td>
                                <td>{historyData.expense_receipt.toLocaleString()}</td>
                                <td class="history-emphasisB">{(historyData.expense_tax + historyData.expense_safety + historyData.expense_receipt).toLocaleString()}</td>
                                <td class="history-emphasisD">{(historyData.material + historyData.material_ledger + historyData.material_tax + historyData.material_receipt + historyData.labor_office + historyData.labor_technical + historyData.labor_daily + historyData.expense_tax + historyData.expense_safety + historyData.expense_receipt).toLocaleString()}</td>
                                <td>{historyData.our_non_payment.toLocaleString()}</td>
                                {#each subcontractList as list, index}
                                    <td class="history-hidden">{historyData[`subcontract_${index}_labor`].toLocaleString()}</td>
                                    <td class="history-hidden">{historyData[`subcontract_${index}_tax`].toLocaleString()}</td>
                                    <td class="history-hidden history-emphasisB">{(historyData[`subcontract_${index}_labor`] + historyData[`subcontract_${index}_tax`]).toLocaleString()}</td>
                                {/each}
                                <td class="history-hidden history-emphasisD">{(historyData[`subcontract_0_labor`] + historyData[`subcontract_0_tax`] + historyData[`subcontract_1_labor`] + historyData[`subcontract_1_tax`] + historyData[`subcontract_2_labor`] + historyData[`subcontract_2_tax`] + historyData[`subcontract_3_labor`] + historyData[`subcontract_3_tax`] + historyData[`subcontract_4_labor`] + historyData[`subcontract_4_tax`]).toLocaleString()}</td>
                                <td class="history-emphasisC">{(historyData.material + historyData.material_ledger + historyData.material_tax + historyData.material_receipt + historyData.labor_office + historyData.labor_technical + historyData.labor_daily + historyData.expense_tax + historyData.expense_safety + historyData.expense_receipt + historyData[`subcontract_0_labor`] + historyData[`subcontract_0_tax`] + historyData[`subcontract_1_labor`] + historyData[`subcontract_1_tax`] + historyData[`subcontract_2_labor`] + historyData[`subcontract_2_tax`] + historyData[`subcontract_3_labor`] + historyData[`subcontract_3_tax`] + + historyData[`subcontract_4_labor`] + historyData[`subcontract_4_tax`]).toLocaleString()}</td>
                                <td>{historyData.outsorucing_our_rental.toLocaleString()}</td>
                                <td>{historyData.outsourcing_subcontract_rental.toLocaleString()}</td>
                                <td>{historyData.note ? historyData.note : "-"}</td>
                                <td id="printHidden"><button on:click={() => {alert("완료된 결재는 수정이 불가능합니다.")}} class="bg-gray-200 text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">수정</button></td>
                                <td id="printHidden"><button on:click={() => {handleLogModal(historyData.id)}} class="bg-teal-500 text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">수정내역</button></td>
                            </tr>
                        {/each}
                        <tr class="table-total">
                            <td>전회 집행금액 합계</td> 
                            <td>{done_original_contract.toLocaleString()}</td>
                            <td>{done_receive_amount.toLocaleString()}</td>
                            <td>{done_outsoucing_execution_possible_price.toLocaleString()}</td>
                            <td>{done_material.toLocaleString()}</td>
                            <td>{done_material_ledger.toLocaleString()}</td>
                            <td>{done_material_tax.toLocaleString()}</td>
                            <td>{done_material_receipt.toLocaleString()}</td>
                            <td>{done_material_subtotal.toLocaleString()}</td>
                            <!-- <td>{done_labor_office.toLocaleString()}</td> -->
                            <td>{(done_labor_office + done_labor_technical).toLocaleString()}</td>
                            <td>{done_labor_daily.toLocaleString()}</td>
                            <td>{done_labor_subtotal.toLocaleString()}</td>
                            <td>{done_expense_tax.toLocaleString()}</td>
                            <td>{done_expense_safety.toLocaleString()}</td>
                            <td>{done_expense_receipt.toLocaleString()}</td>
                            <td>{done_expense_subtotal.toLocaleString()}</td>
                            <td>{done_our_total.toLocaleString()}</td>
                            <td>{done_our_non_payment.toLocaleString()}</td>
                            {#each subcontractList as list, index}
                                {#if index == 0}
                                <td class="history-hidden">{done_subcontract_0_labor.toLocaleString()}</td>
                                <td class="history-hidden">{done_subcontract_0_tax.toLocaleString()}</td>
                                <td class="history-hidden">{done_subcontract_0_subtotal.toLocaleString()}</td>
                                {/if}
                                {#if index == 1}
                                <td class="history-hidden">{done_subcontract_1_labor.toLocaleString()}</td>
                                <td class="history-hidden">{done_subcontract_1_tax.toLocaleString()}</td>
                                <td class="history-hidden">{done_subcontract_1_subtotal.toLocaleString()}</td>
                                {/if}
                                {#if index == 2}
                                <td class="history-hidden">{done_subcontract_2_labor.toLocaleString()}</td>
                                <td class="history-hidden">{done_subcontract_2_tax.toLocaleString()}</td>
                                <td class="history-hidden">{done_subcontract_2_subtotal.toLocaleString()}</td>
                                {/if}
                                {#if index == 3}
                                <td class="history-hidden">{done_subcontract_3_labor.toLocaleString()}</td>
                                <td class="history-hidden">{done_subcontract_3_tax.toLocaleString()}</td>
                                <td class="history-hidden">{done_subcontract_3_subtotal.toLocaleString()}</td>
                                {/if}
                                {#if index == 4}
                                <td class="history-hidden">{done_subcontract_4_labor.toLocaleString()}</td>
                                <td class="history-hidden">{done_subcontract_4_tax.toLocaleString()}</td>
                                <td class="history-hidden">{done_subcontract_4_subtotal.toLocaleString()}</td>
                                {/if}
                            {/each}
                            {#if subcontractList.length !== 0}
                                <td class="history-hidden">{done_sub_total.toLocaleString()}</td>
                            {/if}
                            <td>{done_total.toLocaleString()}</td>
                            <td>{done_outsorucing_our_rental.toLocaleString()}</td>
                            <td>{done_outsourcing_subcontract_rental.toLocaleString()}</td>
                            <td>-</td>
                            <td id="printHidden">-</td>
                            <td id="printHidden">-</td>
                        </tr> 
                    {/if}
                    {#each historyProceed as historyData, proceedIndex}
                        <tr id="printHidden">
                            <td>{historyData.calculate_date}</td>
                            <td class="history-emphasisA">{historyData.original_contract.toLocaleString()}</td>
                            <td class="history-emphasisA">{historyData.receive_amount.toLocaleString()}</td>
                            <td class="history-emphasisA">{historyData.outsoucing_execution_possible_price.toLocaleString()}</td>
                            <td>{historyData.material.toLocaleString()}</td>
                            <td>{historyData.material_ledger.toLocaleString()}</td>
                            <td>{historyData.material_tax.toLocaleString()}</td>
                            <td>{historyData.material_receipt.toLocaleString()}</td>
                            <td class="history-emphasisB">{(historyData.material + historyData.material_ledger + historyData.material_tax + historyData.material_receipt).toLocaleString()}</td>
                            <!-- <td>{historyData.labor_office.toLocaleString()}</td> -->
                            <td>{(historyData.labor_office+historyData.labor_technical).toLocaleString()}</td>
                            <td>{historyData.labor_daily.toLocaleString()}</td>
                            <td class="history-emphasisB">{(historyData.labor_office + historyData.labor_technical + historyData.labor_daily).toLocaleString()}</td>
                            <td>{historyData.expense_tax.toLocaleString()}</td>
                            <td>{historyData.expense_safety.toLocaleString()}</td>
                            <td>{historyData.expense_receipt.toLocaleString()}</td>
                            <td class="history-emphasisB">{(historyData.expense_tax + historyData.expense_safety + historyData.expense_receipt).toLocaleString()}</td>
                            <td class="history-emphasisD">{(historyData.material + historyData.material_ledger + historyData.material_tax + historyData.material_receipt + historyData.labor_office + historyData.labor_technical + historyData.labor_daily + historyData.expense_tax + historyData.expense_safety + historyData.expense_receipt).toLocaleString()}</td>
                            <td>{historyData.our_non_payment.toLocaleString()}</td>
                            {#each subcontractList as list, index}
                                <td class="history-hidden">{historyData[`subcontract_${index}_labor`].toLocaleString()}</td>
                                <td class="history-hidden">{historyData[`subcontract_${index}_tax`].toLocaleString()}</td>
                                <td class="history-hidden history-emphasisB">{(historyData[`subcontract_${index}_labor`] + historyData[`subcontract_${index}_tax`]).toLocaleString()}</td>
                            {/each}
                            {#if subcontractList.length !== 0}
                                <td class="history-hidden history-emphasisD">{(historyData[`subcontract_0_labor`] + historyData[`subcontract_0_tax`] + historyData[`subcontract_1_labor`] + historyData[`subcontract_1_tax`] + historyData[`subcontract_2_labor`] + historyData[`subcontract_2_tax`] + historyData[`subcontract_3_labor`] + historyData[`subcontract_3_tax`] + historyData[`subcontract_4_labor`] + historyData[`subcontract_4_tax`]).toLocaleString()}</td>
                            {/if}
                            <td class="history-emphasisC">{(historyData.material + historyData.material_ledger + historyData.material_tax + historyData.material_receipt + historyData.labor_office + historyData.labor_technical + historyData.labor_daily + historyData.expense_tax + historyData.expense_safety + historyData.expense_receipt + historyData[`subcontract_0_labor`] + historyData[`subcontract_0_tax`] + historyData[`subcontract_1_labor`] + historyData[`subcontract_1_tax`] + historyData[`subcontract_2_labor`] + historyData[`subcontract_2_tax`] + historyData[`subcontract_3_labor`] + historyData[`subcontract_3_tax`] + historyData[`subcontract_4_labor`] + historyData[`subcontract_4_tax`]).toLocaleString()}</td>
                            <td>{historyData.outsorucing_our_rental.toLocaleString()}</td>
                            <td>{historyData.outsourcing_subcontract_rental.toLocaleString()}</td>
                            <td>{historyData.note ? historyData.note : "-"}</td>
                            <td id="printHidden"><button on:click={() => {handleHistoryModal(historyData.id)}} class="bg-teal-500 text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">수정</button></td>
                            <td id="printHidden">
                                {#if proceedIndex == 0}
                                <button on:click={() => {handleLogModal(history["main"][history["main"].length -1]["id"])}} class="bg-teal-500 text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">수정내역</button>
                                {:else}
                                <button on:click={() => {handleDeleteHistory(historyData.id)}} class="bg-red-500 text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">삭제</button>
                                {/if}
                            </td>
                        </tr>
                    {/each}
                    <tr class="table-total">
                        <td>금회 집행금액 합계</td> 
                        <td>{proceed_original_contract.toLocaleString()}</td>
                        <td>{proceed_receive_amount.toLocaleString()}</td>
                        <td>{proceed_outsoucing_execution_possible_price.toLocaleString()}</td>
                        <td>{proceed_material.toLocaleString()}</td>
                        <td>{proceed_material_ledger.toLocaleString()}</td>
                        <td>{proceed_material_tax.toLocaleString()}</td>
                        <td>{proceed_material_receipt.toLocaleString()}</td>
                        <td>{proceed_material_subtotal.toLocaleString()}</td>
                        <!-- <td>{proceed_labor_office.toLocaleString()}</td> -->
                        <td>{(proceed_labor_office+proceed_labor_technical).toLocaleString()}</td>
                        <td>{proceed_labor_daily.toLocaleString()}</td>
                        <td>{proceed_labor_subtotal.toLocaleString()}</td>
                        <td>{proceed_expense_tax.toLocaleString()}</td>
                        <td>{proceed_expense_safety.toLocaleString()}</td>
                        <td>{proceed_expense_receipt.toLocaleString()}</td>
                        <td>{proceed_expense_subtotal.toLocaleString()}</td>
                        <td>{proceed_our_total.toLocaleString()}</td>
                        <td>{proceed_our_non_payment.toLocaleString()}</td>
                        {#each subcontractList as list, index}
                            {#if index == 0}
                            <td class="history-hidden">{proceed_subcontract_0_labor.toLocaleString()}</td>
                            <td class="history-hidden">{proceed_subcontract_0_tax.toLocaleString()}</td>
                            <td class="history-hidden">{proceed_subcontract_0_subtotal.toLocaleString()}</td>
                            {/if}
                            {#if index == 1}
                            <td class="history-hidden">{proceed_subcontract_1_labor.toLocaleString()}</td>
                            <td class="history-hidden">{proceed_subcontract_1_tax.toLocaleString()}</td>
                            <td class="history-hidden">{proceed_subcontract_1_subtotal.toLocaleString()}</td>
                            {/if}
                            {#if index == 2}
                            <td class="history-hidden">{proceed_subcontract_2_labor.toLocaleString()}</td>
                            <td class="history-hidden">{proceed_subcontract_2_tax.toLocaleString()}</td>
                            <td class="history-hidden">{proceed_subcontract_2_subtotal.toLocaleString()}</td>
                            {/if}
                            {#if index == 3}
                            <td class="history-hidden">{proceed_subcontract_3_labor.toLocaleString()}</td>
                            <td class="history-hidden">{proceed_subcontract_3_tax.toLocaleString()}</td>
                            <td class="history-hidden">{proceed_subcontract_3_subtotal.toLocaleString()}</td>
                            {/if}
                            {#if index == 4}
                            <td class="history-hidden">{proceed_subcontract_4_labor.toLocaleString()}</td>
                            <td class="history-hidden">{proceed_subcontract_4_tax.toLocaleString()}</td>
                            <td class="history-hidden">{proceed_subcontract_4_subtotal.toLocaleString()}</td>
                            {/if}
                        {/each}
                        {#if subcontractList.length !== 0}
                            <td class="history-hidden">{proceed_sub_total.toLocaleString()}</td>
                        {/if}
                        <td>{proceed_total.toLocaleString()}</td>
                        <td>{proceed_outsorucing_our_rental.toLocaleString()}</td>
                        <td>{proceed_outsourcing_subcontract_rental.toLocaleString()}</td>
                        <td>-</td>
                        <td id="printHidden">-</td>
                        <td id="printHidden">-</td>
                    </tr> 
                    <tr class="project-info-total">
                        <td>총합계</td> 
                        <td>{(done_original_contract + proceed_original_contract).toLocaleString()}</td>
                        <td>{(done_receive_amount + proceed_receive_amount).toLocaleString()}</td>
                        <td>{(done_outsoucing_execution_possible_price + proceed_outsoucing_execution_possible_price).toLocaleString()}</td>
                        <td>{(done_material + proceed_material).toLocaleString()}</td>
                        <td>{(done_material_ledger + proceed_material_ledger).toLocaleString()}</td>
                        <td>{(done_material_tax + proceed_material_tax).toLocaleString()}</td>
                        <td>{(done_material_receipt + proceed_material_receipt).toLocaleString()}</td>
                        <td>{(done_material_subtotal + proceed_material_subtotal).toLocaleString()}</td>
                        <!-- <td>{(done_labor_office + proceed_labor_office).toLocaleString()}</td> -->
                        <td>{(done_labor_office + proceed_labor_office + done_labor_technical + proceed_labor_technical).toLocaleString()}</td>
                        <td>{(done_labor_daily + proceed_labor_daily).toLocaleString()}</td>
                        <td>{(done_labor_subtotal + proceed_labor_subtotal).toLocaleString()}</td>
                        <td>{(done_expense_tax + proceed_expense_tax).toLocaleString()}</td>
                        <td>{(done_expense_safety + proceed_expense_safety).toLocaleString()}</td>
                        <td>{(done_expense_receipt + proceed_expense_receipt).toLocaleString()}</td>
                        <td>{(done_expense_subtotal + proceed_expense_subtotal).toLocaleString()}</td>
                        <td>{(done_our_total + proceed_our_total).toLocaleString()}</td>
                        <td>{(done_our_non_payment + proceed_our_non_payment).toLocaleString()}</td>
                        {#each subcontractList as list, index}
                            {#if index == 0}
                            <td class="history-hidden">{(done_subcontract_0_labor + proceed_subcontract_0_labor).toLocaleString()}</td>
                            <td class="history-hidden">{(done_subcontract_0_tax + proceed_subcontract_0_tax).toLocaleString()}</td>
                            <td class="history-hidden">{(done_subcontract_0_subtotal + proceed_subcontract_0_subtotal).toLocaleString()}</td>
                            {/if}
                            {#if index == 1}
                            <td class="history-hidden">{(done_subcontract_1_labor + proceed_subcontract_1_labor).toLocaleString()}</td>
                            <td class="history-hidden">{(done_subcontract_1_tax + proceed_subcontract_1_tax).toLocaleString()}</td>
                            <td class="history-hidden">{(done_subcontract_1_subtotal + proceed_subcontract_1_subtotal).toLocaleString()}</td>
                            {/if}
                            {#if index == 2}
                            <td class="history-hidden">{(done_subcontract_2_labor + proceed_subcontract_2_labor).toLocaleString()}</td>
                            <td class="history-hidden">{(done_subcontract_2_tax + proceed_subcontract_2_tax).toLocaleString()}</td>
                            <td class="history-hidden">{(done_subcontract_2_subtotal + proceed_subcontract_2_subtotal).toLocaleString()}</td>
                            {/if}
                            {#if index == 3}
                            <td class="history-hidden">{(done_subcontract_3_labor + proceed_subcontract_3_labor).toLocaleString()}</td>
                            <td class="history-hidden">{(done_subcontract_3_tax + proceed_subcontract_3_tax).toLocaleString()}</td>
                            <td class="history-hidden">{(done_subcontract_3_subtotal + proceed_subcontract_3_subtotal).toLocaleString()}</td>
                            {/if}
                            {#if index == 4}
                            <td class="history-hidden">{(done_subcontract_4_labor + proceed_subcontract_4_labor).toLocaleString()}</td>
                            <td class="history-hidden">{(done_subcontract_4_tax + proceed_subcontract_4_tax).toLocaleString()}</td>
                            <td class="history-hidden">{(done_subcontract_4_subtotal + proceed_subcontract_4_subtotal).toLocaleString()}</td>
                            {/if}
                        {/each}
                        {#if subcontractList.length !== 0}
                            <td class="history-hidden">{(done_sub_total + proceed_sub_total).toLocaleString()}</td>
                        {/if}
                        <td>{(done_total + proceed_total).toLocaleString()}</td>
                        <td>{(done_outsorucing_our_rental + proceed_outsorucing_our_rental).toLocaleString()}</td>
                        <td>{(done_outsourcing_subcontract_rental + proceed_outsourcing_subcontract_rental).toLocaleString()}</td>
                        <td>-</td>
                        <td id="printHidden">-</td>
                        <td id="printHidden">-</td>
                    </tr> 
                {:else}
                    <tr class="table-total">
                        <td colspan="50">정산 내역이 없습니다. 추가 버튼을 눌러 내역을 추가해주세요.</td> 
                    </tr> 
                {/if}
            </tbody>
        </table>
    </div>
{#if setHistoryModal}
    <HistoryModal {handleHistoryModal} {handleHistory} {historyIndexData} {history} {projects} {id}/>
{/if}
{#if logModal}
    <LogModal {handleLogModal} {subcontractList} {logIndex}/>
{/if}