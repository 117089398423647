<script>
  import { apiFetch } from "utils/tools.js";
  export let handleUserModal;
  export let items;
  
  let auth;

  const admins= [
    { position: "전체", auth:"0" },
    { position: "담당", auth:"7" },
    { position: "팀장", auth:"6" },
    { position: "자산구매팀장", auth:"5" },
    { position: "경영지원팀장", auth:"4" },
    { position: "총괄", auth:"3" },
    { position: "이사", auth:"2" },
    { position: "대표이사", auth:"1" },
  ]

  const handleUserDelete = async (setIndex) => {
    let confirmSelect = confirm("정말 삭제하시겠습니까?");
    if(confirmSelect) {
      const data = await apiFetch("user", "delete", {id: setIndex});
      alert(data.data);

      if(data.state == '1') {
        document.querySelector(`#table${setIndex}`).style.display = "none";
      }
    }
  }

  const selectUserSearch = async (setAuth) => {

    setAuth == 0
    ? items = await apiFetch("user")
    : items = await apiFetch("user/" + setAuth);

    document.querySelectorAll("#tableBody > tr").forEach((item)=>{
      item.style.display = "table-row";
    })

    
  }

</script>

<form >
  <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
    조회 조건
  </h6>
  <div class="flex flex-wrap" >
    <div class="relative w-full lg:w-3/12 px-4 mb-3">
      <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
        직무
      </div>            
      <select 
        id="grid-username"
        class=" text-sm relative w-full mb-3 h-10 border-0 rounded shadow" 
        bind:value={auth}
      >
      {#each admins as admin}
        <option value={admin.auth}>
            {admin.position}
        </option>
      {/each}
      </select>
    </div>
    <div class="w-full lg:w-1/12 px-4" style='height: 40px; margin:auto 0;'>
      <button  
        class="w-full h-full bg-indigo-500 text-white active:bg-indigo-600  font-bold uppercase align-bottom rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        on:click={() => {selectUserSearch(auth)}}
      >
        조회
      </button>
    </div>
  </div>
</form>

<div class="project-info-wrap" style="margin-top: 10px;">
  <table  class="project-info" style="width: 100%;">
      <thead class="project-info-header">
      <tr>
          <th>번호</th>
          <th>아이디</th>
          <th>비밀번호</th>
          <th>이름</th>
          <th>직급</th>
          <th>수정</th>
          <th>삭제</th>
      </tr>
      </thead>
      <tbody id="tableBody" class="project-info-body">
        {#await items}
          <tr>
            <td colspan="7">Loading</td>
          </tr>
        {:then items } 
          {#if !items[0]}
            <tr>
              <td colspan="7">데이터가 없습니다.</td>
            </tr>
          {/if}
          {#each items as item, index}
          <tr id="table{item.id}" style="background:{index%2 == 0 ? null : "#f8f8f8"};">
            <td>{index + 1}</td>
            <td>{item.identity}</td>
            <td>{item.password}</td>
            <td>{item.name}</td>
            {#each admins as admin}
              {#if admin.auth == item.auth}
                <td>{ admin.position}</td>
              {/if}
            {/each}
            <td class="text-center">
              <button  
                class="align-middle text-center bg-indigo-500 text-white active:bg-indigo-600  font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button" 
                on:click={() => {handleUserModal(item.id)}}
              >
                  수정
              </button>
            </td>
            <td class="text-center">
                <button  
                  class="align-middle text-center bg-red-500 text-white active:bg-red-300  font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button" 
                  on:click={() => {handleUserDelete(item.id)}}
                >
                  삭제
                </button>
            </td>
          </tr>
          {/each}
        {:catch error}
        <tr>
          <td colspan="7">오류가 발생했습니다.</td>
        </tr>
        {/await}
      </tbody>
  </table>
</div>