<script>
  import { apiFetch, getCookie } from "utils/tools.js"

  let userData = JSON.parse(getCookie("user"))
  let identity = userData.identity
  let name = userData.name
  let currentPassword, newPassword, newPasswordCheck;


  const handleMyInfoUpdate = async () => {

    if(newPassword !== newPasswordCheck) {
      return alert("비밀번호 확인이 일치하지 않습니다.")
    }

    let params = {
      identity,
      name,
      currentPassword,
    }

    if (newPassword) {
      params["newPassword"] = newPassword
      params["newPasswordCheck"] = newPasswordCheck
    };

    console.log(params)
    if (newPassword) params["newPassword"] = newPassword

    const response = await apiFetch("myinfo", "PATCH", params);
    alert(response.data);
    if(response.state == 1){
      currentPassword = "";
      newPassword = "";
      newPasswordCheck = "";
    }
  }
</script>
  
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-6/12 px-4">
          <div class="flex-auto px-4 lg:px-10 pt-0">
            <div class="text-blueGray-400 text-center mb-3 font-bold">
              <small>내 정보 수정</small>
            </div>
            <form on:submit|preventDefault={ handleMyInfoUpdate }>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-id"
                >
                  아이디
                </label>
                <input
                  id="grid-id"
                  bind:value={identity}
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="아이디(4자~12자)"
                  required
                />
              </div>
             
              <div class="relative w-full mb-3">
                <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        for="grid-name"
                >
                  이름
                </label> 
                <input
                        id="grid-name"
                        bind:value={name}
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="이름을 입력해주세요"
                        required
                />
              </div>
              <div class="relative w-full mb-3">
                <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        for="grid-currentPassword"
                >
                  기존 비밀번호
                </label> 
                <input
                  id="grid-currentPassword"
                  bind:value={currentPassword}
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="정보 변경시 필수 입력"
                  required
                  autoComplete="off"
                />
              </div>
              <div class="flex">
                <div class="relative w-full mb-3 mr-1">
                  <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          for="grid-newPassword"
                  >
                    비밀번호 변경(선택)
                  </label> 
                  <input
                    id="grid-newPassword"
                    bind:value={newPassword}
                    type="password"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="비밀번호(4자~12자)"
                    autoComplete="off"
                  />
                </div>
                <div class="relative w-full mb-3 ml-1">
                  <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          for="grid-newPasswordCheck"
                  >
                    비밀번호 변경 확인
                  </label> 
                  <input
                    id="grid-newPasswordCheck"
                    bind:value={newPasswordCheck}
                    type="password"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="비밀번호 확인"
                    autoComplete="off"
                  />
                </div>
              </div>
              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  저장
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
  </div>  