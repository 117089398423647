export const url = (url = "") => {

    return "http://server.kr-elec.com/"+url
}

export const getCookie = (cookieName) => {
    let name, data;
    let cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        name = cookies[i].substring(0, cookies[i].indexOf('='));
        data = cookies[i].substring(cookies[i].indexOf('=') + 1);
        name = name.replace(/^\s+|\s+$/g, '');
        if (name == cookieName) {
            return data;
        }
    }
}

export const deleteCookie = (cookieName) => {
	document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
}

export const apiFetch = async (urlParams = "", method = "get", setParams) => {

    let api = url(urlParams)

    let option = {
        method: method,
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'authorization': getCookie("token"),
        },
    };

    if (setParams) option["body"] = JSON.stringify(setParams);
    
    try {
        const response = await fetch(api, option);
        const res = await response.json();

        if(res.state == 419 || res.state == 401 || res.state == -1) {
            window.location.href = "/";
            deleteCookie("token");
            deleteCookie("user");
            alert(res.data);
        } else {
            return res;
        }
    } catch (error) {
        return {state: 0, data: "[ERROR] 관리자에게 문의주세요.\n"+ "MESSAGE: " + error.message};
    };
};