<script>
    import { apiFetch, getCookie } from "utils/tools.js"; 
    import { navigate } from "svelte-routing";

    export let divisions;

    let division, team_id, name;
    let userData = JSON.parse(getCookie("user"))

    const handleProjectCreate = async () => {

        const params = {
            division, 
            team_id, 
            name,
            subcontract_0: document.querySelector("#subcontract0")?.value,
            subcontract_1: document.querySelector("#subcontract1")?.value,
            subcontract_2: document.querySelector("#subcontract2")?.value,
            subcontract_3: document.querySelector("#subcontract3")?.value,
            subcontract_4: document.querySelector("#subcontract4")?.value,
        }

        const postTeams = await apiFetch("project", "post", params)
        alert (postTeams.data)

        if(postTeams.state == 1){
            navigate(`/project/${postTeams.result.insertId}`, { replace: true });
        }
    }

    let projectFrom;
    let subcontractNumber = 0
    const addSubcontract = () => {
        if(subcontractNumber == 5){
            return alert("공동도급사는 5곳을 초과할 수 없습니다.")
        }
        let subcontractFrom = 
        `<div id="subcontract">
            <div
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2
            ">
                공동 도급사 ${subcontractNumber+1}
            </div>
            <input
                id="subcontract${subcontractNumber}"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all mb-3 duration-150"
                required
                placeholder="공동 도급사 이름을 입력해주세요."
            />
        </div>
        `

        projectFrom.insertAdjacentHTML('beforeend', subcontractFrom)
        subcontractNumber ++
        if(subcontractNumber == 5){
            return document.querySelector("#addSubcontractButton").style.display = "none";
        }
    }

    const subcontractDelete = () => {
        const subcontract = document.querySelectorAll("#subcontract")
        if(subcontract.length == 0) {
            alert("제거할 공동 도급사가 없습니다.")
        }
        subcontractNumber --
        subcontract[subcontract.length - 1].remove();
        document.querySelector("#addSubcontractButton").style.display = "block";
    }

</script>
<div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-6/12 px-4">
            <form  on:submit|preventDefault={handleProjectCreate}>
                <div bind:this={projectFrom} class="modal-form">
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        구분
                    </div>
                    <select 
                        id="grid-username"
                        class=" text-sm relative w-full mb-3 h-10 border-0 rounded shadow" 
                        bind:value={division}
                        required
                    >
                    {#each divisions as division}
                        <option value={division.id}>
                            {division.name}
                        </option>
                    {/each}
                    </select>
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2 ">
                        팀
                    </div>
                    <input class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all mb-3 duration-150" type="text" readonly value={userData.team_name}>
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        프로젝트 명
                    </div>
                    <input
                        bind:value={name}
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all mb-3 duration-150"
                        required
                    />
                </div>            
                <button
                    class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="submit"
                >
                    프로젝트 등록
                </button>
            </form>         
            <button 
                class="bg-indigo-500 text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mt-5 ease-linear transition-all duration-150"
                on:click={subcontractDelete}
                style="float: right;"
                type="button"
            >
                공동 도급사 제거
            </button>
            <button 
                id="addSubcontractButton"
                class="bg-indigo-500 text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mt-5 ease-linear transition-all duration-150"
                on:click={addSubcontract}
                style="float: right;"
                type="button"
            >
                공동 도급사 추가
            </button>
  
        </div>
    </div>
</div>