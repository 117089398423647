<script>
  import {url} from "utils/tools.js";
  import { deleteCookie } from "utils/tools.js";
  console.log("testtes")
  let identity;
  let password;
  const handleSubmit = async () => {
    deleteCookie("user");
    
    const api = url("login")
    const params = {
      identity,
      password,
    }

    const option = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(params)
    };

    try {
        const response = await fetch(api, option);
        const res = await response.json();
        if(res.state == 0) {
          alert(res.data)
        } else {
          document.cookie = `token = ${res.token}`;
          document.cookie = `user = ${JSON.stringify(res.user)}`;
          window.location.reload();
        }
    } catch (error) {
        alert("[ERROR] 관리자에게 문의주세요.\n"+ error.message);
    };
  };
</script>
<div class="login-bg">
  <img src="/assets/img/register_bg_2.png" alt="">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full px-4" style="max-width: 400px;">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
      >
        <div class="rounded-t mb-0 px-6 py-2">
          <img src="/assets/img/logo-main.png" class="logo-main" alt="">
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form on:submit|preventDefault={ handleSubmit }>
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-email"
              >
                아이디
              </label>
              <input
                bind:value={identity}
                required
                id="grid-email"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="아이디"
              />
            </div>

            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-password"
              >
                비밀번호
              </label>
              <input
                bind:value={password}
                required
                id="grid-password"
                type="password"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="비밀번호"
              />
            </div>
            <div class="text-center mt-6">
              <button
                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                type="submit"
              >
                <a href="/">로그인</a>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="flex flex-wrap mt-6 relative">
      </div>
    </div>
  </div>
</div>
