<script>
    import { apiFetch } from "utils/tools.js"

    export let id;
    export let historyIndexData;
    export let handleHistory;
    export let handleHistoryModal;
    export let projects;

    let calculate_date = new Date().toISOString().substring(0, 10), 
        original_contract = 0, 
        receive_amount = 0, 
        outsoucing_execution_possible_price = 0, 
        material = 0, 
        material_ledger = 0, 
        material_tax = 0, 
        material_receipt = 0, 
        labor_office = 0, 
        labor_technical = 0, 
        labor_daily = 0, 
        expense_tax = 0, 
        expense_safety = 0, 
        expense_receipt = 0, 
        our_non_payment = 0, 
        outsorucing_our_rental = 0, 
        outsourcing_subcontract_rental = 0, 
        note = "-";

    let projectList = [];
    projects.then((items) => {
        items.forEach((item)=>{
            if(item.id == id) {
                for(let i = 0; i < items.length; i++) {
                    if(item[`subcontract_${i}`]) projectList = [...projectList, item[`subcontract_${i}`]];
                }
            }
        })
    });

    let subLabor = ["0", "0", "0", "0", "0"];
    let subTex = ["0", "0", "0", "0", "0"];
    if(historyIndexData) {
        calculate_date = historyIndexData.calculate_date, 
        original_contract = historyIndexData.original_contract, 
        receive_amount = historyIndexData.receive_amount, 
        outsoucing_execution_possible_price = historyIndexData.outsoucing_execution_possible_price, 
        material = historyIndexData.material, 
        material_ledger = historyIndexData.material_ledger, 
        material_tax = historyIndexData.material_tax, 
        material_receipt = historyIndexData.material_receipt, 
        labor_office = historyIndexData.labor_office, 
        labor_technical = historyIndexData.labor_technical, 
        labor_daily = historyIndexData.labor_daily, 
        expense_tax = historyIndexData.expense_tax, 
        expense_safety = historyIndexData.expense_safety, 
        expense_receipt = historyIndexData.expense_receipt, 
        our_non_payment = historyIndexData.our_non_payment, 
        subLabor[0] = historyIndexData.subcontract_0_labor, 
        subTex[0] = historyIndexData.subcontract_0_tax, 
        subLabor[1] = historyIndexData.subcontract_1_labor, 
        subTex[1] = historyIndexData.subcontract_1_tax, 
        subLabor[2] = historyIndexData.subcontract_2_labor, 
        subTex[2] = historyIndexData.subcontract_2_tax, 
        subLabor[3] = historyIndexData.subcontract_3_labor, 
        subTex[3] = historyIndexData.subcontract_3_tax,  
        subLabor[4] = historyIndexData.subcontract_4_labor, 
        subTex[4] = historyIndexData.subcontract_4_tax,  
        note = historyIndexData.note;
        outsorucing_our_rental = historyIndexData.outsorucing_our_rental;
        outsourcing_subcontract_rental = historyIndexData.outsourcing_subcontract_rental;
    }
    
    const hendleHistory = async () => {

        let params = {
            project_id: id,
            calculate_date, 
            original_contract, 
            receive_amount, 
            outsoucing_execution_possible_price, 
            material, 
            material_ledger, 
            material_tax, 
            material_receipt, 
            labor_office, 
            labor_technical, 
            labor_daily, 
            expense_tax, 
            expense_safety, 
            expense_receipt, 
            our_non_payment,
            subcontract_0_labor: subLabor[0], 
            subcontract_0_tax: subTex[0], 
            subcontract_1_labor: subLabor[1], 
            subcontract_1_tax: subTex[1], 
            subcontract_2_labor: subLabor[2], 
            subcontract_2_tax: subTex[2], 
            subcontract_3_labor: subLabor[3], 
            subcontract_3_tax: subTex[3], 
            subcontract_4_labor: subLabor[4], 
            subcontract_4_tax: subTex[4], 
            note,
            outsorucing_our_rental,
            outsourcing_subcontract_rental
        }

        let res;

        historyIndexData
        ? res = await apiFetch(`project/outsourcing/history/${historyIndexData.id}`, "put", params)
        : res = await apiFetch(`project/outsourcing/history/${id}`, "post", params)

        alert(res.data)

        if(res.state == 1) {
            handleHistoryModal()
            await handleHistory(1)
        }
    };

</script>

<div class="modal-form">
    <div class="modal-cover"></div>
    <div class="modal">
        <i 
            class="fas fa-times modal-close"
            on:click={handleHistoryModal}
        ></i>
        <div class="text-blueGray-400 text-center mb-3 font-bold">
            <p>조회 내역</p>
        </div>
        <form on:submit|preventDefault={hendleHistory}>
            <div class="modal-form">
                <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    정산일
                </div>
                <input
                    bind:value={calculate_date}
                    required
                    type="date"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
            <div class="text-blueGray-400 text-sm mt-3 font-bold uppercase">
                기성금액
            </div>
            <div class="flex" style="align-items: flex-end;">
                <div class="modal-form w-full">
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        당사기성금액
                    </div>
                    <input
                        bind:value={original_contract}
                        required
                        type="number" 
                        on:mousewheel={(e)=>{e.preventDefault()}}
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
                <div class="modal-form w-full pl-3">
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        공동도급사<br>기성급액(당사분)
                    </div>
                    <input
                        bind:value={receive_amount}
                        required
                        type="number" 
                        on:mousewheel={(e)=>{e.preventDefault()}}
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
                <div class="modal-form w-full pl-3">
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        외주 집행 가능 금액
                    </div>
                    <input
                        bind:value={outsoucing_execution_possible_price}
                        required
                        type="number" 
                        on:mousewheel={(e)=>{e.preventDefault()}}
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
            <div class="text-blueGray-400 text-sm mt-3 font-bold uppercase">
                자재비
            </div>
            <div class="flex">
                <div class="modal-form w-full">
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        계산서
                    </div>
                    <input
                        bind:value={material}
                        required
                        type="number" 
                        on:mousewheel={(e)=>{e.preventDefault()}}
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
                <div class="modal-form w-full pl-3">
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        영수증
                    </div>
                    <input
                        bind:value={material_ledger}
                        required
                        type="number" 
                        on:mousewheel={(e)=>{e.preventDefault()}}
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
                <div class="modal-form w-full pl-3">
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        세금계산서
                    </div>
                    <input
                        bind:value={material_tax}
                        required
                        type="number" 
                        on:mousewheel={(e)=>{e.preventDefault()}}
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
                <div class="modal-form w-full pl-3">
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        카드
                    </div>
                    <input
                        bind:value={material_receipt}
                        required
                        type="number" 
                        on:mousewheel={(e)=>{e.preventDefault()}}
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
            <div class="text-blueGray-400 text-sm mt-3 font-bold uppercase">
                노무비
            </div>
            <div class="flex">
                <div class="modal-form w-full" style="display: none;">
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        상용(사무직)
                    </div>
                    <input
                        bind:value={labor_office}
                        required
                        type="number" 
                        on:mousewheel={(e)=>{e.preventDefault()}}
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
                <div class="modal-form w-full">
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        상용
                    </div>
                    <input
                        bind:value={labor_technical}
                        required
                        type="number" 
                        on:mousewheel={(e)=>{e.preventDefault()}}
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
                <div class="modal-form w-full pl-3">
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        일용
                    </div>
                    <input
                        bind:value={labor_daily}
                        required
                        type="number" 
                        on:mousewheel={(e)=>{e.preventDefault()}}
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
            <div class="text-blueGray-400 text-sm mt-3 font-bold uppercase">
                경비
            </div>
            <div class="flex">
                <div class="modal-form w-full">
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        세금계산서
                    </div>
                    <input
                        bind:value={expense_tax}
                        required
                        type="number" 
                        on:mousewheel={(e)=>{e.preventDefault()}}
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
                <div class="modal-form w-full pl-3">
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        안전관리비
                    </div>
                    <input
                        bind:value={expense_safety}
                        required
                        type="number" 
                        on:mousewheel={(e)=>{e.preventDefault()}}
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
                <div class="modal-form w-full pl-3">
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        카드, 영수증
                    </div>
                    <input
                        bind:value={expense_receipt}
                        required
                        type="number" 
                        on:mousewheel={(e)=>{e.preventDefault()}}
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
            <div class="text-blueGray-400 text-sm mt-3 font-bold uppercase">
                기타
            </div>
            <div class="flex">
                <div class="modal-form w-full">
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        미지급금액
                    </div>
                    <input
                        bind:value={our_non_payment}
                        required
                        type="number" 
                        on:mousewheel={(e)=>{e.preventDefault()}}
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
                <div class="modal-form w-full pl-3">
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        비고
                    </div>
                    <input
                        bind:value={note}
                        required
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
            </div>
            <div class="text-blueGray-400 text-sm mt-3 font-bold uppercase">
                대여금액
            </div>
            <div class="flex">
                <div class="modal-form w-full">
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        외주업체 <i class="fas fa-caret-right"></i> 당사 대여금액
                    </div>
                    <input
                        bind:value={outsorucing_our_rental}
                        required
                        type="number" 
                        on:mousewheel={(e)=>{e.preventDefault()}}
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
                <div class="modal-form w-full pl-3">
                    <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        외주업체 <i class="fas fa-caret-right"></i> 공동도급사 대여금액
                    </div>
                    <input
                        bind:value={outsourcing_subcontract_rental}
                        required
                        type="number" 
                        on:mousewheel={(e)=>{e.preventDefault()}}
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
            </div>
            {#if projectList[0]}
                <hr class="mt-6 border-b-1 border-blueGray-300" />
                <div class="text-blueGray-400 text-sm mt-3 font-bold uppercase">
                    공동도급사 집행금액
                </div>
                {#each projectList as item, index}
                    <div class="flex">
                        <div class="modal-form w-full">
                            <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                {item} - 노무비
                            </div>
                            <input
                                bind:value={subLabor[index]}
                                required
                                type="number" 
                                on:mousewheel={(e)=>{e.preventDefault()}}
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            />
                        </div>
                        <div class="modal-form w-full pl-3">
                            <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                {item} - 세금계산서
                            </div>
                            <input
                                bind:value={subTex[index]}
                                required
                                type="number" 
                                on:mousewheel={(e)=>{e.preventDefault()}}
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            />
                        </div>
                    </div>
                {/each}
            {/if}
            <button 
                class="bg-indigo-500 text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                style="width:100%;"
            >
                저장
            </button>
        </form>
    </div>
</div>
