<script>    
    import { onMount } from "svelte";
    import ProjectInfoModal from 'components/Project/Outsourcing/Modal/ProjectInfoModal.svelte';
    import { apiFetch } from 'utils/tools.js'
    export let id;
    export let projects;
    export let thisProject;

    let projectInfoData
    let setProjectModal = false;
    let projectList = [];
    let sumSub = 0;
    let sumOutsourcing = 0;
    let numberOfSubcontract = 0;

    const handleProjectModal = () => {
        setProjectModal = !setProjectModal;
    }

    const handleSubcontract = async () => {
        let items = await projects
        items.forEach((item)=>{
            if(item.id == id) {
                for(let i = 0; i < items.length; i++) {
                    if(item[`subcontract_${i}`]) {
                        projectList = [...projectList, item[`subcontract_${i}`]];
                        numberOfSubcontract = ++numberOfSubcontract;
                    }
                }
            }
        })
    }

    const handleProjectInfoGet = async () => {
        
        [projectInfoData] = await apiFetch(`project/outsourcing/info/${id}`)

        sumSub = projectInfoData.contractor_price
        sumOutsourcing = projectInfoData.contractor_outsourcing_price
        if(projectInfoData && numberOfSubcontract){
            for(let i = 0; i < numberOfSubcontract; i++) {
                sumSub += projectInfoData[`subcontract_${i}_price`]
                sumOutsourcing += projectInfoData[`subcontract_${i}_outsourcing_price`]
            }
        }
    }

    onMount(()=>{
        handleSubcontract();
        handleProjectInfoGet();
    })

</script>

<div style="display: flex; justify-content: space-between; align-items: center;">
    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold">
        프로젝트 정보
    </h6>
    {#if thisProject && thisProject.state == 1}
        <button 
            class="bg-indigo-500 text-white font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
            type="button"
            style="height: fit-content;"
            on:click={handleProjectModal}
        >
            {projectInfoData ? "수정" : "추가"}
        </button>
    {/if}
</div>

<div class="project-info-wrap" style="max-width: max-content;">
    <table class="project-info">
        <thead class="project-info-header">
            <tr>
                {#if !projectInfoData}
                    <th style="width: 250px;" colspan="2">계약업체(주관사)</th>
                    <th style="width: 550px;" colspan="4">준공예정일</th>
                {:else}
                    <th style="width: 250px;" colspan="2">계약업체(주관사) : {projectInfoData.contractor}</th>
                    <th style="width: 550px;" colspan="4">준공예정일 : {projectInfoData.due_date}</th>
                {/if}
            </tr>
        </thead>
        <tbody class="project-info-body">
            {#if !projectInfoData}
                <tr>
                    <td>공동도급사</td>
                    <td>데이터가 없습니다.</td>
                    <td>외주금액</td>
                    <td>데이터가 없습니다.</td>
                    <td>실행률</td>
                    <td>데이터가 없습니다.</td>
                </tr>
            {:else}
                <tr>
                    <td>{projectInfoData.contractor}</td>
                    <td>{projectInfoData.contractor_price.toLocaleString()}</td>
                    <td>외주금액</td>
                    <td>{projectInfoData.contractor_outsourcing_price.toLocaleString()}</td>
                    <td>실행률</td>
                    <td>{projectInfoData.contractor_execution_rate}%</td>
                </tr>      
                {#each projectList as item, index}
                    <tr>
                        <td>{item}</td>
                        <td>{projectInfoData[`subcontract_${index}_price`].toLocaleString()}</td>
                        <td>외주금액</td>
                        <td>{projectInfoData[`subcontract_${index}_outsourcing_price`].toLocaleString()}</td>
                        <td>실행률</td>
                        <td>{projectInfoData[`subcontract_${index}_execution_rate`]}%</td>
                    </tr>            
                {/each}
                <tr class="project-info-total">
                    <td>합계</td>
                    <td>{sumSub.toLocaleString()}</td>
                    <td>합계</td>
                    <td colspan="4">{sumOutsourcing.toLocaleString()}</td>
                </tr>
            {/if}
        </tbody>
    </table>
</div>

{#if setProjectModal}
    <ProjectInfoModal {handleProjectModal} {handleProjectInfoGet} {projectList} {id} {projectInfoData}/>
{/if}