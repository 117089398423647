<script>
    import { onMount } from "svelte";
    import { link } from "svelte-routing";
    import { apiFetch, getCookie } from "utils/tools.js"
    import ModifyModal from "components/ProjectManagement/Modal/ModifyModal.svelte";
    export let divisions;
    let projectModal = false;
    let modifyProjectInfo;
    let subcontracts;
    let projectAll;
    let switchingActive = 0;
    let items;

    let userData = JSON.parse(getCookie("user"))
    const handleProjectDelete = async (setIndex) => {
        let confirmSelect = confirm("정말 삭제하시겠습니까?");
        if(confirmSelect) {
            const data = await apiFetch("project", "delete", {id: setIndex});
            alert(data.data)

            if (data.state == '1'){
                document.querySelector(`#table${setIndex}`).style.display = "none";
            }
        }
    }

    const handleProjectModal = async (setIndex) => {
        if (setIndex) {
            subcontracts = await apiFetch(`project/${setIndex}`)
            let projectAllList = projectAll
            projectAllList.forEach((item)=>{
                if(item["id"] == setIndex) modifyProjectInfo = item;
            })
        }
        projectModal = !projectModal
    }

    const handleProject = async () => {
        projectAll = await apiFetch("project/team")
        items = projectAll;
    }

    onMount(() => {
        handleProject()
    })


    const handelSwitchButton = (setType) => {
        switchingActive = setType
        items = []

        if (setType == 0){
            return items = projectAll;
        } 

        projectAll.map(item => {

            if (setType == 1) {
                if(item.division == 1){
                    items = [...items, item];
                }
            } else {
                if(item.division == 2){
                    items = [...items, item];
                }
            }
        })
    }

</script>
<div class="switching-tap mb-5"> 
    <div
        on:click={()=>{handelSwitchButton(0)}}
        class="team-button {switchingActive == 0 ? "bg-red-500 text-white" : "bg-white text-blueGray-700"} active:bg-red-300 outline-none focus:outline-none shadow hover:shadow-lg ease-linear transition-all duration-150">
        전체
    </div>
    <div 
        on:click={()=>{handelSwitchButton(1)}}
        class="team-button {switchingActive == 1 ? "bg-red-500 text-white" : "bg-white text-blueGray-700"} active:bg-red-300 outline-none focus:outline-none shadow hover:shadow-lg ease-linear transition-all duration-150">
        직영
    </div>
    <div 
        on:click={()=>{handelSwitchButton(2)}}
        class="team-button {switchingActive == 2 ? "bg-red-500 text-white" : "bg-white text-blueGray-700"} active:bg-red-300 outline-none focus:outline-none shadow hover:shadow-lg ease-linear transition-all duration-150">
        외주
    </div>
</div>
<div class="project-info-wrap">
    <table class="project-info" style="width: 100%;">
        <thead class="project-info-header">
            <tr>
                <th>번호</th>
                <th>프로젝트</th>
                <th>팀</th>
                <th>시작일</th>
                <th>수정</th>
                {#if userData.auth < 4}
                <th>삭제</th>
                {/if}
            </tr>
        </thead>
        <tbody class="project-info-body">
            {#if items}
                {#each items as item, index}
                    <tr id="table{item.id}" style="background:{index%2 == 0 ? null : "#f8f8f8"};">
                        <td>{index + 1}</td>
                        <td>
                            <a href="/project/{item.id}" class="management-detail" use:link>{item.name}</a>
                        </td>
                        {#if userData.auth >= 6}
                            <td>{userData.team_name}</td>
                        {:else}
                            <td>{item.team_name}</td>
                        {/if}
                        <td>{item.created_at.substring(0, 10)}</td>
                        <td class="text-center">
                            <button  
                                class="align-middle text-center bg-indigo-500 text-white active:bg-indigo-600  font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button" 
                                on:click={() => {handleProjectModal(item.id)}}
                            >
                                수정
                            </button>
                        </td>
                        {#if userData.auth < 4}
                        <td class="text-center">
                            <button  
                                class="align-middle text-center bg-red-500 text-white active:bg-red-300  font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button" 
                                on:click={() => {handleProjectDelete(item.id)}}
                            >
                                삭제
                            </button>
                        </td>
                        {/if}
                    </tr>
                {/each}
            {/if}
        </tbody>
    </table>
</div>

{#if projectModal}
    <ModifyModal {divisions} {projectAll} {subcontracts} {modifyProjectInfo} {handleProject} {handleProjectModal}/>
{/if}