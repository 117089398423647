<script>
    import { apiFetch, getCookie } from "utils/tools.js"
    import { onMount } from "svelte";
    import DirectProject from "components/Project/DirectProject.svelte";
    import OutsourcingProject from "components/Project/OutsourcingProject.svelte";
    import Search from 'components/Project/Search.svelte';
    import Card from "../layouts/CardLayout.svelte";
    import { Link } from "svelte-routing";

    export let title = "프로젝트 조회"
    export let id;
    let division = 1;

    const userInfo = JSON.parse(getCookie("user"))
    
    $: id;
    $: teams = apiFetch("team");
    $: projects = apiFetch("project");

    onMount( async ()=>{
        if (id) {
            let [project] = await apiFetch(`project/${id}`);
            division = project.division
        }
    })

</script>

<Card {title}>
    <div class="flex flex-wrap">
        <div class="w-full lg:w-12/12 px-4">
            <Search {teams} {projects} {id}/>
            {#if id}
                {#if division == 1}
                    <DirectProject {id} {projects} {userInfo}/>
                {:else if division == 2}
                    <OutsourcingProject {id} {projects} {userInfo}/>
                {/if}
            {/if}
        </div>
    </div>
</Card>
{#if id}
<Link to="project/print/{id}" id="printHidden" class="print">
    <i class="fas fa-paste"></i>
</Link>
{/if}