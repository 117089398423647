<script>
  import { apiFetch } from "utils/tools.js"
  import Card from "../layouts/CardLayout.svelte";
  import Tap from "components/Team/Tap.svelte";
  import TeamModify from "components/Team/TeamModify.svelte";
  import TeamAdd from "components/Team/TeamAdd.svelte";
  import TeamModal from "components/Team/Modal/TeamModal.svelte";
  export let title = "팀 관리"

  let switchingActive = false;  
  let teamModal = false;
  let managerList = [];
  let teamLeaderList = [];    
  let modifyTeamInfo;

  $: items = apiFetch("team")
  const handelTeamRead = async () => {
    items = await apiFetch("team")
  }

  const listSorting = () => {
    apiFetch("team/users").then((items) => {
      items.forEach((item) => {
        item.auth == 7
        ? managerList = [...managerList, item]
        : teamLeaderList = [...teamLeaderList, item]
      })
    })
  }

  listSorting()

  const handelSwitchButton = () => {
    switchingActive = !switchingActive
  }

  const handleTeamModal = async (setIndex) => {
    if (setIndex) {
      let teamList = await items
      teamList.forEach( (item)=>{
          if(item["id"] == setIndex) modifyTeamInfo = item;
      })
    }

    teamModal = !teamModal
  }

</script>

<Card {title}>
  <Tap {handelSwitchButton} {switchingActive}/>
  {#if !switchingActive}
    <TeamAdd {handelSwitchButton} {handelTeamRead}/>
  {:else}
    <TeamModify {handleTeamModal} {items}/>
  {/if}
</Card>

{#if teamModal}
  <TeamModal {handleTeamModal} {handelTeamRead} {managerList} {teamLeaderList} {items} {modifyTeamInfo} />
{/if}