<script>
    export let history;
    export let id;
    export let projects;

    let subcontractList = [];

    projects.then((items) => {
        items.forEach((item, index)=>{
            if(item.id == id) {
                for(let i = 0; i < 5; i++) {
                    if(item[`subcontract_${i}`]) {
                        subcontractList = [...subcontractList, item[`subcontract_${i}`]]
                    }
                }
            }
        })
    });
    let outsoucing_execution_possible_price = 0,
        outsorucing_our_rental = 0, 
        outsourcing_subcontract_rental = 0,
        subBeforeTotal = [0, 0, 0, 0, 0],
        subNowTotal = [0, 0, 0, 0, 0],
        subtotal = [0, 0, 0, 0, 0],
        before_total = 0,
        executionTotal = 0,
        now_total = 0,
        our_total = 0,
        sub_total = 0,
        total = 0;

    $: history, calculate();

    const calculate = () => {
        outsoucing_execution_possible_price = 0,
        outsorucing_our_rental = 0, 
        outsourcing_subcontract_rental = 0,
        subBeforeTotal = [0, 0, 0, 0, 0],
        subNowTotal = [0, 0, 0, 0, 0],
        subtotal = [0, 0, 0, 0, 0],
        before_total = 0,
        executionTotal = 0,
        now_total = 0,
        our_total = 0,
        sub_total = 0,
        total = 0;

        history["main"].forEach((item, index) => {
            outsoucing_execution_possible_price += parseInt(item["outsoucing_execution_possible_price"])
            outsorucing_our_rental += parseInt(item["outsorucing_our_rental"])
            outsourcing_subcontract_rental += parseInt(item["outsourcing_subcontract_rental"])
            our_total += parseInt(item["material"]) + parseInt(item["material_ledger"]) + parseInt(item["material_tax"]) + parseInt(item["material_receipt"]) + parseInt(item["labor_office"]) + parseInt(item["labor_technical"]) + parseInt(item["labor_daily"]) + parseInt(item["expense_tax"]) + parseInt(item["expense_safety"]) + parseInt(item["expense_receipt"])
            subtotal[0] += parseInt(item["subcontract_0_labor"]) + parseInt(item["subcontract_0_tax"])
            subtotal[1] += parseInt(item["subcontract_1_labor"]) + parseInt(item["subcontract_1_tax"])
            subtotal[2] += parseInt(item["subcontract_2_labor"]) + parseInt(item["subcontract_2_tax"])
            subtotal[3] += parseInt(item["subcontract_3_labor"]) + parseInt(item["subcontract_3_tax"])
            subtotal[4] += parseInt(item["subcontract_4_labor"]) + parseInt(item["subcontract_4_tax"])
            sub_total += parseInt(item["subcontract_0_labor"]) + parseInt(item["subcontract_0_tax"]) + parseInt(item["subcontract_1_labor"]) + parseInt(item["subcontract_1_tax"]) + parseInt(item["subcontract_2_labor"]) + parseInt(item["subcontract_2_tax"]) + parseInt(item["subcontract_3_labor"]) + parseInt(item["subcontract_3_tax"]) + parseInt(item["subcontract_4_labor"]) + parseInt(item["subcontract_4_tax"]);
            total += parseInt(item["material"]) + parseInt(item["material_ledger"]) + parseInt(item["material_tax"]) + parseInt(item["material_receipt"]) + parseInt(item["labor_office"]) + parseInt(item["labor_technical"]) + parseInt(item["labor_daily"]) + parseInt(item["expense_tax"]) + parseInt(item["expense_safety"]) + parseInt(item["expense_receipt"]) + parseInt(item["subcontract_0_labor"]) + parseInt(item["subcontract_0_tax"]) + parseInt(item["subcontract_1_labor"]) + parseInt(item["subcontract_1_tax"]) + parseInt(item["subcontract_2_labor"]) + parseInt(item["subcontract_2_tax"]) + parseInt(item["subcontract_3_labor"]) + parseInt(item["subcontract_3_tax"]) + parseInt(item["subcontract_4_labor"]) + parseInt(item["subcontract_4_tax"]);

            before_total += parseInt(item["material"]) + parseInt(item["material_ledger"]) + parseInt(item["material_tax"]) + parseInt(item["material_receipt"]) + parseInt(item["labor_daily"]) + parseInt(item["labor_office"]) + parseInt(item["labor_technical"]) + parseInt(item["expense_receipt"]) + parseInt(item["expense_safety"]) + parseInt(item["expense_tax"])
            for(let i = 0; i < 5; i++) {
                subBeforeTotal[i] += parseInt(item[`subcontract_${i}_labor`]) + parseInt(item[`subcontract_${i}_tax`])
            };
        });

        history["sub"].forEach((item, index) => {
            outsoucing_execution_possible_price += parseInt(item["outsoucing_execution_possible_price"])
            outsorucing_our_rental += parseInt(item["outsorucing_our_rental"])
            outsourcing_subcontract_rental += parseInt(item["outsourcing_subcontract_rental"])
            our_total += parseInt(item["material"]) + parseInt(item["material_ledger"]) + parseInt(item["material_tax"]) + parseInt(item["material_receipt"]) + parseInt(item["labor_office"]) + parseInt(item["labor_technical"]) + parseInt(item["labor_daily"]) + parseInt(item["expense_tax"]) + parseInt(item["expense_safety"]) + parseInt(item["expense_receipt"])
            subtotal[0] += parseInt(item["subcontract_0_labor"]) + parseInt(item["subcontract_0_tax"])
            subtotal[1] += parseInt(item["subcontract_1_labor"]) + parseInt(item["subcontract_1_tax"])
            subtotal[2] += parseInt(item["subcontract_2_labor"]) + parseInt(item["subcontract_2_tax"])
            subtotal[3] += parseInt(item["subcontract_3_labor"]) + parseInt(item["subcontract_3_tax"])
            subtotal[4] += parseInt(item["subcontract_4_labor"]) + parseInt(item["subcontract_4_tax"])
            sub_total += parseInt(item["subcontract_0_labor"]) + parseInt(item["subcontract_0_tax"]) + parseInt(item["subcontract_1_labor"]) + parseInt(item["subcontract_1_tax"]) + parseInt(item["subcontract_2_labor"]) + parseInt(item["subcontract_2_tax"]) + parseInt(item["subcontract_3_labor"]) + parseInt(item["subcontract_3_tax"]) + parseInt(item["subcontract_4_labor"]) + parseInt(item["subcontract_4_tax"]);
            total += parseInt(item["material"]) + parseInt(item["material_ledger"]) + parseInt(item["material_tax"]) + parseInt(item["material_receipt"]) + parseInt(item["labor_office"]) + parseInt(item["labor_technical"]) + parseInt(item["labor_daily"]) + parseInt(item["expense_tax"]) + parseInt(item["expense_safety"]) + parseInt(item["expense_receipt"]) + parseInt(item["subcontract_0_labor"]) + parseInt(item["subcontract_0_tax"]) + parseInt(item["subcontract_1_labor"]) + parseInt(item["subcontract_1_tax"]) + parseInt(item["subcontract_2_labor"]) + parseInt(item["subcontract_2_tax"]) + parseInt(item["subcontract_3_labor"]) + parseInt(item["subcontract_3_tax"]) + parseInt(item["subcontract_4_labor"]) + parseInt(item["subcontract_4_tax"]);

            now_total += parseInt(item["material"]) + parseInt(item["material_ledger"]) + parseInt(item["material_tax"]) + parseInt(item["material_receipt"]) + parseInt(item["labor_daily"]) + parseInt(item["labor_office"]) + parseInt(item["labor_technical"]) + parseInt(item["expense_receipt"]) + parseInt(item["expense_safety"]) + parseInt(item["expense_tax"])
            for(let i = 0; i < 5; i++) {
                subNowTotal[i] += parseInt(item[`subcontract_${i}_labor`]) + parseInt(item[`subcontract_${i}_tax`])
            }
        })
        for(let i = 0; i < 5; i++) {
            subtotal[i] =  subBeforeTotal[i] - subNowTotal[i];
        };
        executionTotal = (before_total + now_total) + ((subBeforeTotal[0] + subNowTotal[0]) + (subBeforeTotal[1] + subNowTotal[1]) + (subBeforeTotal[2] + subNowTotal[2]) + (subBeforeTotal[3] + subNowTotal[3]) + (subBeforeTotal[4] + subNowTotal[4]))
    };
</script>

<div style="display: flex; justify-content: space-between; align-items: center;">
    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold">
        금회정산
    </h6>
</div>

<div class="project-info-wrap" style="max-width: max-content;">
    <table class="project-info">
        <thead class="project-info-header">
            <tr>
                <th style="min-width: 130px;">구분</th>
                <th style="min-width: 130px;">지급가능금액</th>
                <th style="min-width: 130px;">기집행금액</th>
                <th style="min-width: 130px;">금회집행금액</th>
                <th style="min-width: 130px;">누계집행금액</th>
                <th style="min-width: 130px;">차액</th>
            </tr>
        </thead>
        <tbody class="project-info-body">
            <tr>
                <td>당사</td>
                <td>{outsoucing_execution_possible_price.toLocaleString()}</td>
                <td>{before_total.toLocaleString()}</td>
                <td>{now_total.toLocaleString()}</td>
                <td>{(before_total + now_total).toLocaleString()}</td>
                <td>{(outsoucing_execution_possible_price - (before_total + now_total)).toLocaleString()}</td>
            </tr>
            {#each subcontractList as item, index}
            <tr>
                <td>{item}</td>
                <td>0</td>
                <td>
                    {subBeforeTotal[index].toLocaleString()}
                </td>
                <td>{subNowTotal[index].toLocaleString()}</td>
                <td>{(subBeforeTotal[index] + subNowTotal[index]).toLocaleString()}</td>
                <td>{(-subBeforeTotal[index] - subNowTotal[index]).toLocaleString()}</td>
            </tr>
            {/each}
                <tr class="table-total">
                    <td>계</td>
                    <td>{outsoucing_execution_possible_price.toLocaleString()}</td>
                    <td>
                        {(before_total + subBeforeTotal[0] + subBeforeTotal[1] + subBeforeTotal[2] + subBeforeTotal[3] + subBeforeTotal[4]).toLocaleString()}
                    </td>
                    <td>
                        {(now_total + subNowTotal[0] + subNowTotal[1] + subNowTotal[2] + subNowTotal[3] + subNowTotal[4]).toLocaleString()}
                    </td>
                    <td>{executionTotal.toLocaleString()}</td>
                    <td>{executionTotal >= 0 ? (outsoucing_execution_possible_price - executionTotal).toLocaleString() : (outsoucing_execution_possible_price + executionTotal).toLocaleString()}</td>
                </tr>
            <tr>
                <td>개인입금금액1</td>
                <td>{outsorucing_our_rental.toLocaleString()}</td>
                <td colspan="4">-</td>
            </tr>
            <tr>
                <td>개인입금금액2</td>
                <td>{outsourcing_subcontract_rental.toLocaleString()}</td>
                <td colspan="4">-</td>
            </tr>
            <tr class="table-total">
                <td>계</td>
                <td>{(outsorucing_our_rental + outsourcing_subcontract_rental).toLocaleString()}</td>
                <td colspan="4">-</td>
            </tr>
            <tr class="project-info-total">
                <td>합계</td>
                <td>{(outsorucing_our_rental + outsourcing_subcontract_rental + outsoucing_execution_possible_price).toLocaleString()}</td>
                <td>{(before_total + subBeforeTotal[0] + subBeforeTotal[1] + subBeforeTotal[2] + subBeforeTotal[3] + subBeforeTotal[4]).toLocaleString()}</td>
                <td>{(now_total + subNowTotal[0] + subNowTotal[1] + subNowTotal[2] + subNowTotal[3] + subNowTotal[4]).toLocaleString()}</td>
                <td>{executionTotal.toLocaleString()}</td>
                <td>{((outsorucing_our_rental + outsourcing_subcontract_rental + outsoucing_execution_possible_price)-executionTotal).toLocaleString()}</td>
            </tr>
        </tbody>
    </table>
</div>