<script>
  import Card from "../layouts/CardLayout.svelte";
  import Dashboard from "components/Dashboard/Dashboard.svelte";
  export let title = "프로젝트 현황"
</script>

<Card {title}>
  <Dashboard />
</Card>

