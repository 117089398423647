<script>
  import { link } from "svelte-routing";
  import { getCookie, deleteCookie } from "utils/tools.js";

  let collapseShow = "hidden";
  let current = window.location.pathname;

  const toggleCollapseShow = (classes) => {
    collapseShow = classes;
  };
  
  window.onpopstate = () => {
    current = window.location.pathname;
  };

  const approvalMenu = [
    {name: "프로젝트 현황", link: "/", icon: "fas fa-newspaper"},
    {name: "프로젝트 조회", link: "/project", icon: "fas fa-desktop"},
    {name: "프로젝트 추가", link: "/project/register", icon: "fas fa-folder-plus"},
    {name: "프로젝트 관리", link: "/project/management", icon: "fas fa-tasks"}
  ];

  const userMenu = [
    {name: "팀 관리", link: "/team", icon: "fas fa-users-cog"},
    {name: "관리자 등록", link: "/user", icon: "fas fa-user-circle"},
    {name: "관리자 수정", link: "/modify-user", icon: "fas fa-tools"},
  ];

  const logoff = () => {
    deleteCookie("user");
    deleteCookie("token");
  }

  const userInfo = JSON.parse(getCookie("user"))

</script>

<nav
  id="printHidden"
  class="side-bar md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
>
  <div
    class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
  >
    <button
      class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
      type="button"
      on:click={() => toggleCollapseShow('bg-white m-2 py-3 px-6')}
    >
      <i class="fas fa-bars"></i>
    </button>

    <img class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0" src ="/assets/img/logo.png" alt="이미지를 표시할 수 없습니다." />

    <div
      class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded {collapseShow}"
    >
      <div
        class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
      >
        <div class="flex flex-wrap">
          <div class="w-6/12 flex justify-end">
            <button
              type="button"
              class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              on:click={() => toggleCollapseShow('hidden')}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <h6 class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
        정산서 관리
      </h6>
      <ul class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
        {#each approvalMenu as menu, index}
          {#if userInfo.auth >= '6'}           
            {#if index !== 1} 
            <li class="items-center">
              <a
                use:link
                href="{menu.link}"
                class="text-xs uppercase py-3 font-bold block {current == menu.link ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
                on:click={() => toggleCollapseShow('hidden')}
                on:click={() => {current = menu.link;}}
              >
                <i
                  class="fas {menu.icon} text-sm {current == menu.link ? 'opacity-75' : 'text-blueGray-300'}"
                  style="width: 20px;"
                ></i>
                  {menu.name}
              </a>
            </li>
            {/if}
          {:else} 
            {#if index !== 2} 
              <li class="items-center">
                <a
                  use:link
                  href="{menu.link}"
                  class="text-xs uppercase py-3 font-bold block {current == menu.link ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
                  on:click={() => toggleCollapseShow('hidden')}
                  on:click={() => {current = menu.link;}}
                >
                  <i
                    class="fas {menu.icon} text-sm {current == menu.link ? 'opacity-75' : 'text-blueGray-300'}"
                    style="width: 20px;"
                  ></i>
                    {menu.name}
                </a>
              </li>
            {/if}
          {/if}
        {/each}
      </ul>
      {#if userInfo.auth == '0'} 
      <hr class="my-4 md:min-w-full" />
      <h6
        class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
      >
        관리자
      </h6>
      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        {#each userMenu as menu}
          <li class="items-center">
            <a
              use:link
              href="{menu.link}"
              class="text-xs uppercase py-3 font-bold block { current == menu.link ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
              on:click={() => toggleCollapseShow('hidden')}
              on:click={() => {current = menu.link;}}
            >
              <i
                class="fas {menu.icon} text-sm { current == menu.link ? 'opacity-75' : 'text-blueGray-300'}"
                style="width: 20px;"
              ></i>
                {menu.name}
            </a>
          </li>
        {/each}
      </ul>
      {/if}
      <hr class="my-4 md:min-w-full" />
      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <li class="items-center">
          <a 
            class="text-xs uppercase py-3 font-bold block { current == "/myinfo" ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
            use:link
            on:click={() => {current = "/myinfo"}}
            href="/myinfo"
          >
          <i 
            class="far fa-address-card text-sm { current == "/myinfo" ? 'opacity-75' : 'text-blueGray-300'}"
            style="width: 20px;"
          ></i>
           내 정보
          </a>
        </li>
        <li class="items-center">
          <a 
            class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
            href="/"
            on:click={logoff}
          >
          <i class="fas fa-sign-out-alt text-blueGray-300 text-sm opacity-75"
            style="width: 20px;"
          ></i>
           로그아웃
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
