<script>
  import { getCookie } from "utils/tools.js";

  export let id;
  export let teams;
  export let projects;

  const divisions = [
    { id: 1, name: `직영` },
    { id: 2, name: `외주` },
  ];

  let selectedYear, divisionId, teamId, projectId;
  let filteredProject = [];
  let projectCopy = projects;

  projects.then((items)=>{
    items.forEach((item) => {
      if(item.state == 1 && id == item.id) {
        teamId = item.team_id;
        projectId = item.id;
        divisionId = item.division;
      }
    })
  })

  const userInfo = JSON.parse(getCookie("user"))

  $: teamId, projectId, divisionId;

  const onChange = async () => {

    projectCopy = await projects
    filteredProject = [];

    const dataFilter = (set, compare) => {

      if(filteredProject[0]) {
        let MultiFilteredproject = [];

        filteredProject.forEach((item) => {
          if (item[set] == compare) {
            MultiFilteredproject = [...MultiFilteredproject, item]
          }
        });
        return MultiFilteredproject
      } 

      projectCopy.forEach((item) => {
        if (item[set] == compare) {
          filteredProject = [...filteredProject, item]
        }
      });
      return filteredProject
    }

    if(divisionId) {
      filteredProject = dataFilter("division", divisionId)
      projectCopy = filteredProject
    }
    if(teamId) {
      projectCopy = dataFilter("team_id", teamId)
    }
  } 
  
  onChange()
  const lookupProject = () => {
    if(!projectId) {
      return alert("프로젝트를 선택해주세요.")
    }
    window.location.href = `/project/${projectId}`
  };

</script>
<form on:submit|preventDefault={lookupProject}>
  <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
    조회 조건
  </h6>
  {#if userInfo.auth > 5}
  <div class="flex flex-wrap" >
    <div class="relative w-full lg:w-1/12 px-4 mb-3">
      <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
        구분
      </div>            
      <select 
        id="grid-username"
        class=" text-sm relative w-full mb-3 h-10 border-0 rounded shadow" 
        bind:value={divisionId}
        on:change={onChange}

      >
      <option value="">전체</option>
      {#each divisions as division}
        <option value={division.id}>
          {division.name}
        </option>
      {/each}
      </select>
    </div>
    <div class="relative w-full lg:w-1/12 px-4 mb-3">
      <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
        팀
      </div>
      <input
        readonly
        value="{userInfo.team_name}"
        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
      />
    </div>
    <div class="relative w-full lg:w-4/12 px-4 mb-3">
      <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
        프로젝트
      </div>
      <select 
        class="text-sm relative w-full mb-3 h-10 border-0 rounded shadow" 
        bind:value={projectId}
      >
      {#key filteredProject}
        {#await projectCopy}
            <option value="">Loding</option>
        {:then projectCopy } 
            <option value="">선택</option>
            {#each projectCopy as project}
            <option value="{project.id}">{project.name}</option>
            {/each}
        {:catch error}
            <option value="">오류</option>
        {/await}
      {/key}
      </select>
    </div>
    <div class="w-full lg:w-1/12 px-4" style='height: 40px; margin:auto 0;'>
      <button  
        class="w-full h-full bg-indigo-500 text-white active:bg-indigo-600  font-bold uppercase align-bottom rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="submit">
        조회
      </button>
    </div>
  </div>
  {:else}
  <div class="flex flex-wrap" >
    <div class="relative w-full lg:w-1/12 px-4 mb-3">
      <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
        구분
      </div>            
      <select 
        id="grid-username"
        class=" text-sm relative w-full mb-3 h-10 border-0 rounded shadow" 
        bind:value={divisionId}
        on:change={onChange}

      >
      <option value="">전체</option>
      {#each divisions as division}
        <option value={division.id}>
          {division.name}
        </option>
      {/each}
      </select>
    </div>
    <div class="relative w-full lg:w-1/12 px-4 mb-3">
      <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
        팀
      </div>
        <select 
          class="text-sm relative w-full mb-3 h-10 border-0 rounded shadow" 
          bind:value={teamId} 
          id="grid-username"
          on:change={onChange}
        >
        {#await teams}
            <option value="">Loding</option>
        {:then teams } 
            <option value="">전체</option>
            {#each teams as team}
            <option value="{team.id}">{team.name}</option>
            {/each}
        {:catch error}
            <option value="">오류</option>
        {/await}
        </select>
    </div>
    <div class="relative w-full lg:w-4/12 px-4 mb-3">
      <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
        프로젝트
      </div>
      <select 
        class="text-sm relative w-full mb-3 h-10 border-0 rounded shadow" 
        bind:value={projectId}
      >
      {#key filteredProject}
        {#await projectCopy}
            <option value="">Loding</option>
        {:then projectCopy } 
            <option value="">선택</option>
            {#each projectCopy as project}
            <option value="{project.id}">{project.name}</option>
            {/each}
        {:catch error}
            <option value="">오류</option>
        {/await}
      {/key}
      </select>
    </div>
    <div class="w-full lg:w-1/12 px-4" style='height: 40px; margin:auto 0;'>
      <button  
        class="w-full h-full bg-indigo-500 text-white active:bg-indigo-600  font-bold uppercase align-bottom rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="submit">
        조회
      </button>
    </div>
  </div>
  {/if}
</form>





