<script>
  import { apiFetch } from "utils/tools.js";
  export let handelSwitchButton;
  export let handelTeamRead;

  let name;
  let manager;
  let leader;

  const handleTeamCreate = async () => {

    const params = {
      name,
      manager,
      leader
    }
    const items = await apiFetch("team", "post", params)

    alert(items.data)

    if (items.state == 1) {
      handelTeamRead()
      handelSwitchButton()
    }
  }
</script>
  
<div class="container mx-auto px-4 h-full">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-6/12 px-4">
      <div class="flex-auto px-4 lg:px-10 pt-0">
        <form on:submit|preventDefault={ handleTeamCreate }>
          <div class="relative w-full mb-3 mt-5">
            <div
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              팀 이름
            </div>
            <input
              bind:value={name}
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              placeholder="팀 이름을 입력해세요."
              required
            />
          </div>
          <div class="text-center mt-6">
            <button
              class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
              type="submit"
            >
              팀 등록
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>  