<script>
  import { onMount } from "svelte";
  import { apiFetch, getCookie } from "utils/tools.js";
  export let modifyProjectInfo;
  export let handleProjectModal;
  export let handleProject;
  export let subcontracts;
  export let projectAll;

  let id = modifyProjectInfo.id;
  let team_id
  let teamName;
  let name = modifyProjectInfo.name;
  let subcontract = [null, null, null, null, null]
  let userData = JSON.parse(getCookie("user"))
  let teams;
  let division;

  subcontracts[0].division == 1
  ? division = "직영"
  : division = "외주"

  if( userData.auth >= 6 ){
    team_id = userData.team_id;
    teamName = userData.team_name;
  } else {
    projectAll.forEach((item)=>{
      if(item.id == id ) {
        team_id = item.team_id;
      }
    })
  }

  const handleTeamGet = async () => {
    const res = await apiFetch(`team`);
    teams = res;
    
  }

  subcontract.forEach((item, index)=>{
    subcontract[index] = subcontracts[0][`subcontract_${index}`]
  })

  const handleProjectUpdate = async () => {
    for(let i = 1; i < 5; i ++) {
      if(subcontract[i] !== "" && subcontract[i] !== null){
        for( let j = i; j >= 1; j --) {
          if(subcontract[j-1] == "" || subcontract[j-1] == null){
            return alert("공동도급사를 순서대로 입력해주세요.")
          }
        }
      }
    }

    const params = {
      name,
      division: subcontracts[0].division,
      subcontract_0: subcontract[0],
      subcontract_1: subcontract[1],
      subcontract_2: subcontract[2],
      subcontract_3: subcontract[3],
      subcontract_4: subcontract[4],
    }

    let res = await apiFetch(`project/${id}`, "put", params);
    alert(res.data)

    if (res.state == 1) {
      handleProject()
      handleProjectModal();
    }
  }

  onMount(()=>{
    handleTeamGet()
  })

</script>

<div class="modal-form">
  <div class="modal-cover" on:click={handleProjectModal}></div>
  <div class="modal">
    <i 
        class="fas fa-times modal-close"
        on:click={handleProjectModal}
    ></i>
    <div class="text-blueGray-400 text-center mb-3 font-bold">
        <p>프로젝트 정보</p>
    </div>
    <form  on:submit|preventDefault={handleProjectUpdate}>
      <div class="modal-form">
        <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
            구분
        </div>
          <input class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all mb-3 duration-150" 
            type="text" 
            readonly bind:value={division}
          />
        <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2 ">
            팀
        </div>
        {#if userData.auth >= 6}
          <input class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all mb-3 duration-150" 
            type="text" 
            readonly value="{teamName}"
          />
        {:else}
          <select 
            id="grid-username"
            class=" text-sm relative w-full mb-3 h-10 border-0 rounded shadow" 
            bind:value={team_id}
            required
          >
          {#if teams}
            {#each teams as item}
                <option value={item.id}>
                    {item.name}
                </option>
            {/each}
          {/if}
          </select>
        {/if}
       
        <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
            프로젝트 명
        </div>
        <input
            bind:value={name}
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all mb-3 duration-150"
            required
        />
        {#each subcontract as item, index}
          <div class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
            공동 도급사 {index + 1}
          </div>
          <input
            bind:value={subcontract[index]}
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all mb-3 duration-150"
          />
        {/each}
      </div>
      <button
        class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
        type="submit"
      >
          프로젝트 수정
      </button>
    </form>
  </div>
</div>